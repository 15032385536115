import React, { useState, useContext, useEffect } from 'react';
import { USER_FORM, INTERNATIONAL_OFFICER_USER_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import AllUsers from './AllUsers';
import Officers from './Officers';
import Admin from './Admin';
import AuthContext from '../../Utils/auth-context';
import { allAdminUsers, allUsers } from '../../Utils/services';
import { toast } from 'react-toastify';
import AdminForm from '../ModalTemplate/AdminForm';
import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"


const a11yProps = (index) => {
  //console.log(index, "index, ")
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const Users = ({match}) => {

  const [tabsValue, setTabsValue] = useState(0);
  const [userData, setUserData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [internationalData, setInternationalData] = useState(null);
  const context = useContext(ModalContext)
  const authCtx = useContext(AuthContext);
  const { handleModalData } = context;
  const [loader, setLoader] = useState(true);

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const getTablesData = async () => {
    try {
      const result = await allUsers();
      //console.log(result, "This is results user All Data");
      if (result.res.user.length > 0) {
        setLoader(false)
        setUserData(result.res.user);
      }
      if (result.res.internationalOfficers.length > 0) {
        setLoader(false)
        setInternationalData(result.res.internationalOfficers);
      }
    }
    catch (error) {
      setLoader(false)
      //console.log(error, "Error");
      toast.error(error.message);
    }
  }

  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      //console.log(error, "Error");
      toast.error(error.message);
    }
  }

  

  useEffect(() => {
    getTablesData();
  }, [])
  

  const tabsData = [
    {
      label: "users",
      data: (
        <>
          <Loader loader={loader} />
          <AllUsers data={userData} getTablesData={getTablesData} />
        </>
      )
    },

    {
      label: "international Officers",
      data: (
        <>
          <Loader loader={loader} />
          <Officers data={internationalData} getTablesData={getTablesData} />
        </>
      )
    },

    authCtx.isSuperAdmin
    &&
    {
      label: "Admin",
      data: (
        <>
          <Loader loader={loader} />
          <Admin match ={match} data={adminData} getAdminTableData={getAdminTableData} />
        </>
      )
    }
  ]

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(<UserForm getTablesData={getTablesData} title="add new user" buttonText="add user" />);
        break;

      case 1:
        handleModalData(<UserForm getTablesData={getTablesData} title="add new international Officer" buttonText="add international Officer" />);
        break;

      case 2:
        handleModalData(<AdminForm refreshDataMethod={getAdminTableData} />);
        break;

      default:
        handleModalData(USER_FORM);
        break;
    }
  }

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            key={index}
            className="px-10"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
}


export default Users;

