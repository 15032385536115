import { useContext, useEffect } from 'react';
import { ModalContext } from "../../Context"
import avatar from "../../Assets/images/avatar.png"
import { navlist } from "./navlist"
import { Link, useLocation } from "react-router-dom";
import classNames from 'classnames';
import { useState } from 'react';
import { BASE_URL_IMG, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';

const SideNav = () => {
    const [adminData, setAdminData] = useState();
    const context = useContext(ModalContext)
    const location = useLocation();

    
    const getAdminDetailsApiData = async () => {
        const id = localStorage.getItem("adminId")
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            if(result.success){
                setAdminData(result.data)
            }

        } catch (error) {
            //console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    return (
        <div id="sideNav">
            <div className="profile-info">
                <img src={adminData && `${BASE_URL_IMG}${adminData.image}?${new Date().getTime()}`} alt="avatar" className='w-2/3 rounded-full' />
                <h3 className="title"> {adminData && adminData.name} </h3>
                <h6 className="sub-title">
                    <Link to="/profile" >
                        Manage Account
                    </Link>
                </h6>
            </div>

            <div className="navlist">
                <ul>
                    {
                        navlist.map(item => (
                            <li key={item.ref}>
                                <Link to={item.path} className={classNames({
                                    'flex items-center justify-center cursor-pointer ': true,
                                    'opacity-100': location.pathname === item.path,
                                    'opacity-50': location.pathname !== item.path
                                })}>
                                    <div className='grid grid-cols-6 gap-4 items-center'>
                                        <span className='col-span-2'>
                                            <img src={item.icon} alt="icon" className='h-8' />
                                        </span>
                                        <span className='uppercase'>
                                            {item.label}
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default SideNav;