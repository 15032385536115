import React, { useState, useContext, useEffect } from 'react';
import { USER_FORM, INTERNATIONAL_OFFICER_USER_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
// import AllUsers from './AllUsers';
// import Officers from './Officers';
// import Admin from './Admin';
import AuthContext from '../../Utils/auth-context';
import { getGeneralNotification } from '../../Utils/services';
import { toast } from 'react-toastify';
import GeneralNotification from './GeneralNotification';
import HubsMinihubsNotification from './HubsMinihubsNotification';
import EventNotification from './EventNotification';
// import AdminForm from '../ModalTemplate/AdminForm';
// import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"
import AddGeneralNotificationForm from '../ModalTemplate/AddGeneralNotificationForm';


const a11yProps = (index) => {
    //console.log(index, "index, ")
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Notification = ({ match }) => {

    const [tabsValue, setTabsValue] = useState(0);
    const context = useContext(ModalContext)
    const [loader, setLoader] = useState(true)
    const authCtx = useContext(AuthContext);
    const { handleModalData } = context;
    const [userData, setUserData] = useState(null);  // change
    const [generalNotificationData, setGeneralNotifucationData]  = useState([]);



    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
    };
    const getTablesData = async (sendData) => {
        try {
            const result = await getGeneralNotification(sendData);
            //console.log(result, "This is results user All Data");
            if (result.res.success) {
                setLoader(false)
                // toast.success(result.res.message)
                setGeneralNotifucationData(result.res.data);
            }
        }
        catch (error) {
            setLoader(false)
            //console.log(error, "Error");
            toast.error(error.message);
        }
    }

    // useEffect(() => {
    //     getTablesData();
    // }, [])

    const tabsData = [
        {
            label: "GENERAL",
            data: (
                <>
                    <Loader loader={loader} />
                    <GeneralNotification totalCount = {10} data={generalNotificationData} getTablesData={getTablesData} />
                </>
            )
        },

        // {
        //     label: "HUBS / MINI HUBS",
        //     data: (
        //         <>
        //             <Loader loader={loader} />
        //             <HubsMinihubsNotification data={userData} getTablesData={getTablesData} />
        //         </>
        //     )
        // },
        // {
        //     label: "EVENT",
        //     data: (
        //         <>
        //             <Loader loader={loader} />
        //             <EventNotification data={userData} getTablesData={getTablesData} />
        //         </>
        //     )
        // }
    ]


    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(<AddGeneralNotificationForm getTablesData={getTablesData} title="GENERAL NOTIFICATION" buttonText="SEND" />);
                break;

            case 1:
                // handleModalData(<UserForm getTablesData={getTablesData} title="add new international Officer" buttonText="add international Officer" />);
                break;

            case 2:
                // handleModalData(<AdminForm refreshDataMethod={getAdminTableData} />);
                break;

            default:
                handleModalData(<AddGeneralNotificationForm getTablesData={getTablesData} title="GENERAL NOTIFICATION" buttonText="SEND" />);
                break;
        }
    }

    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />

            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        key={index}
                        className="px-10"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}


export default Notification;

