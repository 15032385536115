import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyCHS2ebr1tqPIb0F_GcvEnMUGRqValEZKc",
  authDomain: "virani-9c0b5.firebaseapp.com",
  projectId: "virani-9c0b5",
  storageBucket: "virani-9c0b5.appspot.com",
  messagingSenderId: "696956276932",
  appId: "1:696956276932:web:873722bf0b3e5b69353991",
  measurementId: "G-MW3NG88SJ4"
};

const firebaseApp = initializeApp(firebaseConfig);

export { firebaseApp };