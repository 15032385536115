import logo from "../../Assets/images/logo.svg";
import { otpVerificationApi } from "../../Utils/services";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import authBg from "../../Assets/images/banner_new.jpg"
import OtpInput from "react-otp-input";


const OtpVerification = () => {

    const [otp, setOtp] = useState();
    const location = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (location.state !== undefined) {
            const { verification_key, email } = location.state;
            setEmailValue(email)
            setVerificationCode(verification_key)
            //console.log(verification_key, email, "verification_key, email,")
        }
        if(location.state === undefined){
            history.push("/login")
        }
    }, []);

    const handleOtpChange = (e) => {
        //console.log(e)
        setOtp(e);
    }
    const handleOtpSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const result = await otpVerificationApi({
                "email": emailValue,
                "verification_key": verificationCode,
                "otp": parseInt(otp)
            });
            if (result.res.success) {
                setLoading(false)
                toast.success(result.res.message)
                history.push({
                    pathname: "/changepassword",
                    state: {
                        email: result.res.data.email,
                        token: result.res.data.token
                    }
                })
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }


    return (
        <div className="w-full relative">
            <img src={authBg} alt="" />
            <div className="flex absolute top-1/2 left-0 w-full">
                <div className="w-2/5 mx-auto auth-container">
                    <div className="w-1/2 mx-auto text-center">
                        <img
                            src={logo}
                            className="w-1/2 mx-auto mb-10"
                            alt="logo"
                        />
                        <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                            VERIFY OTP
                        </h2>
                        <form className="flex flex-col items-center" onSubmit={handleOtpSubmit}>
                            <div className="mt-2 flex otpSection justify-center items-center">
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={6}
                                    isInputNum={true}
                                />
                            </div>
                            {
                                loading ? <p>Loading...</p> :
                                    <button
                                        className="primary-btn mt-10"
                                        type="submit"
                                    >
                                        Verify OTP
                                    </button>
                            }

                            <span
                                className="text-btn"
                            >
                                <Link to="/login">Remember password ?</Link>
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtpVerification;