import React, { useEffect, useMemo, useContext, useState } from 'react';
import { hubsData, communityMember } from './dummyData';
import Table from '../Common/Table';
import { Button } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";
import { nestedRoutes } from "../../routes"
import Pagination from '@material-ui/lab/Pagination';
import SimpleBreadcrumbs from './SimpleBreadcrumbs';
import { ModalContext } from '../../Context'
import MemberListing from "../ModalTemplate/MemberListing"

import { BASE_URL_IMG, getHubs } from '../../Utils/services';
import { toast } from 'react-toastify';



const Hubs = ({ hubsList, setHubsList }) => {
  const [selectedMember, setSelectedMember] = useState([])
  const [membersList, setMembersList] = useState([])

  let history = useHistory();
  const context = useContext(ModalContext)
  const { handleModalData } = context;



  const goTo = value => {
    const { _id, name } = value.row.original
    history.push({
      pathname: '/master/hub',
      state: { id: _id, name : name },
    })
  }

  const addMember = () => {
    alert('added')
  }


  const getHubsData = async () => {
    try {
      const result = await getHubs();
      const _hubList = result.res;
      setHubsList(_hubList)
    }
    catch (e) {
      toast.error(e.message);
    }
  }

  const viewCommunityMembers = (data) => {
    const HUBS_COMMUNITY_MEMBER = <MemberListing
      data={data.row.original.members}
      title="Community Members"
      selectedValue={selectedMember}
      setSelectedValue={setSelectedMember}
      optionsList={membersList}
      hubId = {data.row.original.id}
      getHubsData ={getHubsData}
      btnText="save"
      onClickHandler={addMember}
      helperText={'*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option*'}
    />
    handleModalData(HUBS_COMMUNITY_MEMBER)
  }

  useEffect(() => {
    getHubsData();
  }, [])


  const hubs = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'col1', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },

      {
        Header: 'Hub Logo',
        accessor: 'logo',
        Cell: ({ cell: { value } }) =>
          <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className="w-10" />
      },

      {
        Header: 'Hub Name',
        accessor: 'name',
      },
      {
        Header: 'URL',
        accessor: data => data.url ? data.url : '-'
      },
      {
        Header: 'Community Members',
        accessor: 'members',
        Cell: props => (
          <div className="flex items-center">
            <span> {props.row.original.members.length} </span>
            <img
              src="/assets/icons/eye.svg"
              alt=""
              className="eye-icon"
              onClick={() => viewCommunityMembers(props)}
            />
          </div>
        )

      },
      {
        Header: 'Hub Abbreviation',
        accessor: 'abbreviation',
      },

      {
        Header: 'Created On',
        accessor: 'createdOn',
        Cell: ({ cell: { value } }) => new Date(value).toDateString()
      },

      {
        Header: 'Number Of Feeds',
        accessor: 'feedCount',
      },

      {
        Header: 'Last Feed Date',
        accessor: 'lastFeedOn',
        Cell: ({ cell: { value } }) => new Date(value).toDateString()
      },

      {
        id: "view",
        Header: "",
        accessor: "view",
        Cell: props => <Button className="btn2" onClick={() => goTo(props)}> View </Button>,
      }
    ],
    []
  )

  return (
    <>
      {/* <div className="flex justify-between items-center">
        <Pagination count={10} variant="outlined" shape="rounded" className="flex items-center" />

        <div>
          <ul className="flex items-center">
            <li className="flex items-center">
              <div className="rounded-full bg-green h-3 w-3 mr-3" />
              Active
              <span />
            </li>

            <li className="flex items-center ml-5">
              <div className="rounded-full bg-inActive h-3 w-3 mr-3" />
              Inactive
              <span />
            </li>
          </ul>
        </div>

        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input type="text" placeholder="Search" className="w-96 mx-3" />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
      </div> */}

      <div className="mt-3">
        <SimpleBreadcrumbs />
      </div>

      <div className="-mt-5">
        <Table columns={hubs} data={hubsList} />
      </div>
    </>
  )
}

export default withRouter(Hubs)