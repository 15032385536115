import { useTable } from 'react-table'
 
const Table = ({columns,data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })
 
  return (
    <table
    className="w-full mt-5 table-theme-1"
    {...getTableProps()}
    cellSpacing={5}
  >
      <thead>
        {headerGroups.map((headerGroup , idx) => (
          <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column , idx) => (
              <th key={idx} {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row , nums) => {
          prepareRow(row)
          return (
            <tr key={nums} {...row.getRowProps()} className="hover-row">
              {row.cells.map((cell , idx) => {
                return (
                  <td key={idx} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table;