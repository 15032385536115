import Table from '../../Common/Table';
import { useMemo } from 'react';
import { Button } from "@material-ui/core";
import { useEffect, useState, useContext } from 'react';
import { getCountriesData } from '../getRequests';
import AddCountry from '../../ModalTemplate/AddCountry'
import { ModalContext } from '../../../Context'
import { BASE_URL_IMG } from '../../../Utils/services';




const Country = ({ setCountryData, countryData }) => {
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    useEffect(() => {
        (async () => {
            const data = await getCountriesData();
            setCountryData(data);
        })();

    }, [])


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const editHandler = (data) => {
        const EDIT_COUNTRY = <AddCountry
            setCountryData={setCountryData}
            title="Edit Country"
            id={data.cell.row.original._id}
            name={data.cell.row.original.name}
            region={data.cell.row.original.region}
            image={data.cell.row.original.flag}
            btnText="SAVE"
            edit
        />
        handleModalData(EDIT_COUNTRY, 'md')
    }



    const countryColumns = useMemo(
        () => [
            {
                Header: 'Image',
                accessor: 'flag',
                Cell: ({ cell: { value } }) =>
                    <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} className="w-10" alt="" />
            },

            {
                Header: 'Country',
                accessor: 'name',
                Cell: ({ cell: { value } }) => <span className="capitalize">{value}</span>
            },
            {
                Header: 'Region',
                accessor: 'region',
                Cell: ({ cell: { value } }) => <span className="capitalize">{value}</span>
            },

            {
                Header: 'Added On',
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                Header: 'Added By',
                accessor: 'createdBy',
                Cell: ({ cell: { value } }) => <div className='flex items-center'><img src={`${BASE_URL_IMG}${value.image}?${new Date().getTime()}`} className=' w-10 h-10 flex object-cover rounded-full mr-2' />{value.name}</div>
            },
            {
                id: "view",
                Header: "Action",
                accessor: "_id",
                Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>,
            }
        ],
        []
    )

    return (
        <>
            {
                countryData &&
                <Table columns={countryColumns} data={countryData.sort(dynamicSort("name"))} />
            }
        </>
    )
}

export default Country