import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { addNonHubMembers, BASE_URL_IMG, getMembers, getNonHubMembersApi, removeMembersHubApi } from '../../Utils/services';
import avatarImg from "../../Assets/images/DummyDp.png"
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';

const MemberListing = ({
    title,
    data,
    // onClickHandler,
    selectedValue,
    setSelectedValue,
    optionsList,
    btnText,
    helperText,
    hubId,
    getHubsData
}) => {
    //console.log(optionsList, "optionsList");
    const [membersList, setMembersList] = useState([])
    const [allFieldsData, setAllFieldsData] = useState({
        members: [],
    })
    const [membersData, setMembersData] = useState(data);
    const context = useContext(ModalContext)
    const { closeModal } = context;
    useEffect(() => {
        getNonHubMembers()
    }, [])


    const getNonHubMembers = async () => {
        const result = await getNonHubMembersApi(hubId)
        setMembersList(result.res.data);
    }
    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'members') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }

    const onClickHandler = async () => {
        try {
            const _membersSend = [];
            allFieldsData.members && allFieldsData.members.map((item) => {
                _membersSend.push(item._id)
            })
            const result = await addNonHubMembers({
                "id": hubId,
                "members": _membersSend
            });
            if (result.res.success) {
                toast.success(result.res.message)
                closeModal();
                getHubsData();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleRemoveMember = async (item) => {
        try {
            const result = await removeMembersHubApi({
                "id": hubId,
                "members": item._id
            });
            if (result.res.success) {
                toast.success(result.res.message)
                const NewData = membersData && membersData.filter((val) => {
                    return item._id !== val._id
                })
                setMembersData(NewData)
                getHubsData();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }
    return (
        <>
            <div className="flex items-center justify-center flex-col">
                <h3 className="title"> {title} </h3>

                <div className="w-75">
                    <ul className="grid grid-cols-4 gap-10 w-full">
                        {
                            membersData.map((item , idx) => (
                                < >
                                    <li key={idx} className="flex items-center">
                                        <div className='flex justify-center items-center'>
                                            <img src={item.image ? `${BASE_URL_IMG}${item.image}?${new Date().getTime()}` : avatarImg} className="round-image" />
                                            {item.name}
                                        </div>
                                        <span className="cross cross_icon_margin" onClick={() => handleRemoveMember(item)} />
                                    </li>
                                </>
                            ))
                        }
                    </ul>

                    {
                        onClickHandler && (
                            <>
                                <div className="grid grid-cols-5 w-full mt-10">
                                    <div className="col-span-2">

                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            name="members"
                                            options={membersList}
                                            getOptionLabel={(option) => option.name}
                                            value={allFieldsData.members}
                                            onChange={(e, newVal) => onInputChange(e, newVal, 'members')}
                                            renderInput={(params) => <TextField {...params} id="badge" label="Add a Member" helperText={helperText} />}
                                        />
                                        {/* <Autocomplete
                                            multiple
                                            id="tags-filled"
                                            options={optionsList.map((option) => option)}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    label="Add Members"
                                                    helperText={helperText}
                                                />
                                            )}
                                        /> */}

                                    </div>
                                </div>

                                <div className="flex justify-center mt-10">
                                    <button className="button-1 mx-auto" onClick={onClickHandler} >
                                        {btnText}
                                    </button>
                                </div>
                            </>
                        )
                    }
                </div>

            </div>
        </>
    )
}

export default MemberListing;