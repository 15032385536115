import React from "react";
import { Line } from "react-chartjs-2";



const ReactChartPage = ({ data }) => {
  //console.log(data, 'datdatata');
  return (
    <div className="w-full">
      <Line data={data} />
    </div>
  );
}

export default ReactChartPage