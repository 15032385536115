import UsersTable from "./UsersTable";
import { useContext, useEffect, useMemo, useState } from "react";
import AntSwitch from "../Common/AntSwitch";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Button, Grid } from "@material-ui/core";
import { ModalContext } from "../../Context";
import UserEditForm from "../ModalTemplate/UserEditForm";
import {
  BASE_URL_IMG,
  InviteAllPeopleNotification,
  InviteNotification,
  userDeactivate,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MaintenanceModeConfirmation from "../ModalTemplate/MaintenanceModeConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const AllUsers = ({ data, getTablesData }) => {
  const [searchValue, setSearchValue] = useState("");
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  useEffect(() => {
    getTablesData();
  }, []);

  const history = useHistory();

  const renderBadgeValue = (value) => {
    return value.map((item, index) => (
      <span className="mr-2" key={index}>
        {index === value.length - 1 ? `${item}` : `${item},`}
      </span>
    ));
  };

  const editHandler = (data) => {
    const EDIT_PARTY = (
      <UserEditForm
        title="Edit user"
        buttonText="Save Changes"
        id={data.row.original._id}
        perticularData={data.row.original}
        getTablesData={getTablesData}
      />
    );
    handleModalData(EDIT_PARTY);
  };

  const handleInvite = async (data) => {
    //console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "user",
        id: data.row.original._id,
      });
      if (result.res.success) {
        toast.success(result.res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleInviteAll = async () => {
    try {
      const result = await InviteAllPeopleNotification({
        people: "user",
      });
      if (result.res.success) {
        toast.success(result.res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUserBulkUpload = () => {
    history.push({
      pathname: "users/bulk-upload",
      state: {
        isInterNationalStatus: false,
      },
    });
  };

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message);
        closeModal();
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeactivate = async (props) => {
    const id = props.row.original._id;
    const USER_DEACTIVATE = (
      <MaintenanceModeConfirmation
        title="Change User Status"
        data={modalTextsFile.userActivateDeactivate}
        action={() => handleMaintenance(id)}
      />
    );
    handleModalData(USER_DEACTIVATE, "sm");
  };

  const userColumns = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status", // accessor is the "key" in the data
        Cell: ({ cell: { value } }) =>
          value ? (
            <div className="rounded-full bg-green h-3 w-3"></div>
          ) : (
            <div className="rounded-full bg-orange h-3 w-3"></div>
          ),
      },
      {
        Header: "Users",
        accessor: (userData) => (
          <div className="flex justify-start items-center">
            {
              <img
                src={
                  userData.image
                    ? `${BASE_URL_IMG}${userData.image}?${new Date().getTime()}`
                    : ""
                }
                onError={(e)=> e.target.src="/assets/DummyDp.png"}
                alt=""
                className="w-12 h-12 rounded-full"
              />
            }

            <span className="ml-2">
              {userData.name ? userData.name : " - "}
            </span>
          </div>
        ),
        // Cell: ({ cell: { value } }) =>
        //   <div className="flex justify-start items-center">
        //     <img src={value.image} alt="" />
        //     <span className="ml-2">{value}</span>
        //   </div>
      },
      {
        Header: "Title",
        accessor: (Value) => {
          return (
            <>
              {
                <span>
                  {Value && Value.designation ? Value.designation : " - "}
                </span>
              }
            </>
          );
        },
      },
      {
        Header: "Country, Region",
        accessor: (properties) => {
          return (
            <>
              <span className="capitalize">{`${
                properties.country ? properties.country : " - "
              } , ${properties.region ? properties.region : " - "}`}</span>
            </>
          );
        },
      },
      {
        Header: "Party",
        accessor: (party) => {
          return <span>{party.party ? party.party : " - "}</span>;
        },
      },
      {
        Header: "Badge",
        accessor: "badge",
        Cell: ({ cell: { value } }) => (
          <div className="flex justify-start items-center">
            {value?.img && (
              <img
                src={`${BASE_URL_IMG}${value.img}?${new Date().getTime()}`}
                alt=""
              />
            )}
            <span className="ml-2">{renderBadgeValue(value)}</span>
          </div>
        ),
      },
      {
        Header: "Directory Access",
        accessor: "access",
        Cell: ({ cell: { value } }) => (
          <>
            {/* <AntSwitch
              checked={value}
              name="isDirectory"
              disabled
            /> */}
            <span>{value ? "Yes" : "No"}</span>
          </>
        ),
      },
      {
        Header: "Action",
        accessor: "_id",
        Cell: (props) => (
          <Button className="btn2" onClick={() => editHandler(props)}>
            {" "}
            Edit{" "}
          </Button>
        ),
      },
      {
        Header: "Notification",
        accessor: "",
        Cell: (props) => (
          <Button className="btn2" onClick={() => handleInvite(props)}>
            {" "}
            Invite{" "}
          </Button>
        ),
      },
      {
        Header: "Status",
        accessor: "deactivated",
        Cell: (props) => (
          <Grid item>
            <AntSwitch
              checked={!props.row.original.deactivated}
              onChange={() => handleDeactivate(props)}
              name="isDirectory"
            />
          </Grid>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="flex justify-between items-center">
        {/* <Pagination count={10} variant="outlined" shape="rounded" className="flex items-center" /> */}
        {/* <div>
          <ul className="flex items-center">
            <li className="flex items-center">
              <div className="rounded-full bg-green h-3 w-3 mr-3"></div>
              Active
              <span />
            </li>

            <li className="flex items-center ml-4">
              <div className="rounded-full bg-orange h-3 w-3 mr-3"></div>
              Submit
              <span />
            </li>
          </ul>
        </div> */}

        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        {/* <Button className="btn2" onClick={handleInviteAll} disabled> <span className='px-2'>Invite All</span> </Button> */}
        <Button className="btn2" onClick={handleUserBulkUpload}>
          {" "}
          <span className="px-2">Bulk Upload</span>{" "}
        </Button>
      </div>
      {data && (
        <UsersTable
          columns={userColumns}
          data={data.filter((val, index) => {
            if (searchValue === "") {
              return val;
            } else if (
              val.name &&
              val.name.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              val.party &&
              val.party.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              val.country &&
              val.country.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              val.region &&
              val.region.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              val.email &&
              val.email.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              val.designation &&
              val.designation.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return val;
            } else if (
              (val.access && val.access ? "yes" : "no").includes(
                searchValue.toLowerCase()
              )
            ) {
              return val;
            } else if (val.badge && val.badge.length > 0) {
              for (let i = 0; i <= val.badge.length; i++) {
                if (
                  val.badge[i] &&
                  val.badge[i].toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return val;
                }
              }
            }
          })}
        />
      )}
    </>
  );
};

export default AllUsers;
