import { useMemo, useState, useEffect, useContext } from "react"
import Table from '../../Common/Table'
import { Button } from "@material-ui/core";
import { ModalContext } from '../../../Context'
import AddExpertise from '../../ModalTemplate/AddExpertise'
import { getExpertiseData } from '../getRequests';
import { BASE_URL_IMG } from "../../../Utils/services";





const Expertise = ({
    setExpertiseData,
    expertiseData,
}) => {
    const context = useContext(ModalContext)
    const { handleModalData } = context;


    useEffect(() => {
        (async () => {
          const data = await getExpertiseData();
          setExpertiseData(data);
        })();
    
    }, [])


    const editHandler = (data) => {
        const EDIT_EXPERTISE = <AddExpertise
            setExpertiseData={setExpertiseData}
            area={data.cell.row.original.area}
            id={data.cell.row.original._id}
            title="Edit Expertise"
            btnText="SAVE"
            edit
        />
        handleModalData(EDIT_EXPERTISE, 'md')
    }

    const expertiseColumns = useMemo(
        () => [
            {
                Header: 'Area of Expertise',
                accessor: 'area', // accessor is the "key" in the data
            },
            {
                Header: 'Usage Counter',
                accessor: 'usage'
            },
            {
                Header: 'Added By',
                accessor: 'createdBy',
                Cell: ({ cell: { value } }) => <div className='flex items-center'><img src={`${BASE_URL_IMG}${value.image}?${new Date().getTime()}`} className="w-10 h-10 flex object-cover rounded-full mr-2"/>{value.name}</div>
            },
            {
                Header: 'Added On',
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                Header: "Action",
                accessor:"_id",
                Cell:  props => <Button className="btn2" onClick={()=>editHandler(props)}>Edit</Button>,
            }
        ],
        []
    )

    return (
        <>
            {expertiseData &&
                <Table columns={expertiseColumns} data={expertiseData} />
            }
        </>
    )
}

export default Expertise