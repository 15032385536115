import Flagged from "./Components/Flagged";
import Events from "./Components/Events";
import Home from "./Components/Home";
import Request from "./Components/Request";
import Users from "./Components/Users";
import Feeds from './Components/Feeds'
import Master from './Components/Master'
import HubDetailPage from "./Components/Master/HubDetailPage";
import MiniHubDeatilPage from "./Components/Master/MiniHubDetailPage";
import SectionPage from "./Components/Master/SectionPage";
import Hubs from "./Components/Master/Hubs";
import ManageAcoount from "./Components/ManageAccount";
import AdminViewPage from "./Components/Users/AdminViewPage";
import Notification from "./Components/Notification";
import UserBulkUpload from "./Components/Users/UserBulkUpload";

export const routes = [
  { path: "/", component: Home },
  { path: "users", component: Users },
  { path: "users/bulk-upload", component: UserBulkUpload },
  { path: "notification", component: Notification },

  {path : "users/:slug", component : AdminViewPage},
  { path: "flagged", component: Flagged },
  { path: "feeds", component: Feeds },
  { path: "requests", component: Request },
  { path: "events", component: Events },
  { path: "master", component: Master },
  { path: "profile", component:  ManageAcoount},
  // new from nestedRoutes
  { path: "master/:slug", component: HubDetailPage },
  { path: "master/:slug/:slug1", component: MiniHubDeatilPage },
  { path: "master/:slug/:slug1/:slug2", component: SectionPage },


  // new from nestedRoutes

];

export const nestedRoutes = [
  { path: "master/", component: Hubs },
  // { path: "master/:slug", component: HubDetailPage },
  // { path: "master/:slug/:slug1", component: MiniHubDeatilPage },
  // { path: "master/:slug/:slug1/:slug2", component: SectionPage },
]
