import React, { useState, useMemo, useContext } from 'react';
import PostDetail from "../Common/PostDetail"
// import TrueIcon from "../../Assets/images/icons/trueicon.svg"
import { ModalContext } from '../../Context'
import { Button } from "@material-ui/core";
import classNames from "classnames";
import avatar from "../../Assets/images/avatar.png"
import { BASE_URL_IMG, flagReinstate } from '../../Utils/services'
import { toast } from "react-toastify";
import { filterPostType } from "../../functions"
import PostDetails from "../ModalTemplate/PostDetails"


const RemovedPost = ({
    divider,
    data,
    getRemovedFlag
}) => {
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }


    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails data={data} removedPost={true} isModalView={true}/>
        handleModalData(POST_MODAL)
    }


    const reinstateHandler = async () => {
        const body = { id: data._id }
        try {
            const res = await flagReinstate(body)
            getRemovedFlag()
            toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }


    return (
        <div className={classNames({
            "removed-post grid grid-cols-6 gap-x-4 py-16": true,
            divider: divider
        })}>
            <div className="left col-span-4">
                { filterPostType(data, showPostDetail) }
                {/* <PostDetail data={data} /> */}
            </div>

            <div className="right col-span-2 flex flex-col justify-between items-center">
                <ul className="custom-list">
                    <li className="flex items-center">
                        <label className="primary-color mr-3">
                            Removed On:
                        </label>
                        { data.removed && formatDate(data.removed.removedOn) }
                    </li>

                    <li className="flex items-center">
                        <label className="primary-color mr-3">
                            Removed By:
                        </label>
                        <img src={`${BASE_URL_IMG}${data.postedBy.image}?${new Date().getTime()}`} className="avatar" alt="" />
                        { data.postedBy.name }
                    </li>
                </ul>

                <Button
                    variant="outlined"
                    className="approval_button_style w-40"
                    onClick={reinstateHandler}
                >
                    <span className="pl-1">
                        REINSTATE POST
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default RemovedPost;