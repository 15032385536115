import styles from '../../Assets/Comment.module.css'
import { BASE_URL_IMG } from '../../Utils/services'


const CommentBox = ({ commentBoxDataProps, singlePostData }) => {
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    return (
        <>
            {
                singlePostData && singlePostData.map((data , index) => {
                    return (
                        <div className="comment-bg border-b p-3" key={index}>
                            <div className="flex justify-start">
                                <div>
                                    <img src={`${BASE_URL_IMG}/user/${data.user}/${data.user}.jpeg?${new Date().getTime()}`} className=" w-8 h-8 object-cover rounded-full" alt="" />
                                </div>
                                <div className="pl-2">
                                    <p className={styles.comment}>
                                        <span className={styles.name}>{data.name}</span>
                                        {data.content}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start pt-2">
                                <div className={styles.num}>{formatDate(data.date)}</div>
                                <div className="flex pl-4"><span className={styles.num}>{data.likes && data.likes.length}</span>
                                    {/* <img src="../../assets/comment/like.svg" alt="" className={styles.icon} /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} width="21.144" height="18.658" viewBox="0 0 21.144 18.658"><g transform="translate(0 0)"><g transform="translate(0 0)"><path className="a" d="M19.479,1.715a5.681,5.681,0,0,0-8.086,0l-.8.8-.8-.8A5.718,5.718,0,0,0,1.715,9.8L10.6,18.683,19.479,9.8a5.681,5.681,0,0,0,0-8.086" transform="translate(-0.025 -0.025)" /></g></g></svg>

                                </div>
                                <div className="flex pl-4"><span className={styles.num}>{data.replies && data.replies.length}</span>
                                    {/* <img src="../../assets/comment/comment.svg" alt="" className={styles.icon} /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} width="17.622" height="18.659" viewBox="0 0 17.622 18.659"><path className="a" d="M15.2-1.332H2.423A2.628,2.628,0,0,0,0,1.456v9a2.629,2.629,0,0,0,2.414,2.788v4.084l5.1-4.083H15.2a2.628,2.628,0,0,0,2.423-2.789v-9A2.628,2.628,0,0,0,15.2-1.332ZM12.906,9.126H4.717V7.937h8.189Zm0-2.535H4.717V5.4h8.189Zm0-2.535H4.717V2.867h8.189Zm0,0" transform="translate(0 1.332)" /></svg>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default CommentBox