import React, { useState, useMemo, useContext } from 'react';
import LatestPostCard from "../Common/LatestPostCard"
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from "../ModalTemplate/modalTextsFile"
import { ModalContext } from '../../Context'
import SectionWritePostEditer from '../ModalTemplate/SectionWritePostEditer';
import { deleteResourcesApi } from '../../Utils/services';
import { toast } from 'react-toastify';



const LatestPosts = ({ fetchSectionDetails, data }) => {
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const removeResources = async (id) => {
        try {
            const result = await deleteResourcesApi(id);
            if(result.res.success){
                toast.success(result.res.message)
                closeModal();
                fetchSectionDetails();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const removeHandler = (id) => {
        const REMOVE_ATTACHMENT = <RemoveConfirmation
            title="REMOVE ATTACHMENT ==>"
            data={modalTextsFile.attachmentRemoveText}
            action={removeResources}
            id={id}
        />
        handleModalData(REMOVE_ATTACHMENT, 'sm')
    }

    const modalSectionWritePost = <SectionWritePostEditer
        fetchSectionDetails={fetchSectionDetails}
    />

    const handleSectionWritePost = () => {
        handleModalData(modalSectionWritePost)
    }

    return (
        <div>
            <div className="flex justify-between items-center p-4  border-b-2 pl-0 sticky top-0 bg-white z-50">
                <h3 className="primary-color font-semibold text-l"> Latest Posts</h3>
                <span
                    className="cursor-pointer flex items-center primary-color font-semibold text-l"
                    onClick={handleSectionWritePost}
                >
                    ADD NEW
                    <img src="/assets/icons/plus.svg" alt="" className="ml-3 w-8" />
                </span>
            </div>

            <div>
                {
                    data.map((item , idx) => (
                        <LatestPostCard key={idx} fetchSectionDetails={fetchSectionDetails} data={item} removeHandler={() => removeHandler(item._id)} />
                    ))
                }
                {/* <LatestPostCard removeHandler={removeHandler} />
                <LatestPostCard removeHandler={removeHandler} /> */}
            </div>
        </div>
    )
}

export default LatestPosts;