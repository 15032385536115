import React, { useState, useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import UsersTable from './UserTable';
import crossRedIcon from "../../Assets/images/icons/cross-red.svg";
import { BASE_URL_IMG } from '../../Utils/services';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const GeneralNotification = ({ data, getTablesData }) => {
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getTablesData({
      page: page,
      size: postsPerPage
    });
  }, [page])

  let { path } = useRouteMatch();
  const history = useHistory();
  const param = useParams();
  //console.log(param, "Param")

  const adminColumns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: <div className='flex items-center'><span>Target Group</span> <span className='ml-1 RotateArrowSign'>{">"}</span></div>,
        accessor: 'generalDetails',
        Cell: ({ cell: { value } }) => (
          <>
            {
              <div>
                <span>{value && value.target && value.target} {value && value.target !== "all" ? " : " : " "} </span>
                {
                  value && value.party && value.party.map((item , idx) => {
                    return (
                      <span key={idx}>{`${item.name} , `}</span>
                    )
                  })
                }
                {
                  value && value.country &&
                  <span>{value.country.join(" , ")}</span>
                }
                {
                  value && value.organization && value.organization.map((item , idx) => {
                    return (
                      <span key={idx}>{`${item.name} , `}</span>
                    )
                  })
                }
                {
                  value && value.region &&
                  <span>{value.region.join(" , ")}</span>
                }
                {
                  value && value.thinktank && value.thinktank.map((item , idx) => {
                    return (
                      <span key={idx}>{`${item.name} , `}</span>
                    )
                  })
                }
              </div>
            }
          </>
        )
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ cell: { value } }) => (
          <>
            <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className='w-20' />
          </>
        )
      },
      {
        Header: "Actions",
        accessor: 'badge',
        Cell: ({ cell: { value } }) => (
          <div className='flex items-center'>
            {/* <span>
              <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                type="button"
              >
                <span className="MuiButton-label">View</span>
              </button>
            </span> */}
            {/* <span className='px-2'>
              <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                type="button"
              >
                <span className="MuiButton-label">RESEND</span>
              </button>
            </span> */}
            
            <span className='border-2 p-1.5 border-red-600 border-solid'>
              <img src={crossRedIcon} alt="" />
            </span>
    
          </div>
        )
      }
    ], [])

  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <Pagination
            count={10}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          />
        </div>
        {/* <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input type="text" placeholder="Search" className="w-96 mx-3" />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div> */}
      </div>
      {
        data && (
          <UsersTable columns={adminColumns} data={data} />
        )
      }
    </>
  )
}

export default GeneralNotification;