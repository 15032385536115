import React, { useState, useContext, useEffect } from 'react';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import HubsRouting from "./HubsRouting"
import Country from './Country/Country'
import Regions from './Regions/Regions';
import { ModalContext } from '../../Context'
import HubsForm from '../ModalTemplate/HubsForm'
import AddRegion from '../ModalTemplate/AddRegion'
import AddCountry from '../ModalTemplate/AddCountry'
import AddExpertise from '../ModalTemplate/AddExpertise'
import Expertise from './Expertise/Expertise'
import Affiliation from './Affiliation';
import AffiliationForm from '../ModalTemplate/AffiliationForm';
import { submitHub, fetchMembers } from './getRequests';
import { getHubs } from '../../Utils/services';
import { toast } from "react-toastify";
import Loader from "../Common/Loader"



const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}




const Master = () => {

  const [tabsValue, setTabsValue] = useState(0);
  const [expertiseData, setExpertiseData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [affiliationData, setAffiliationData] = useState([]);
  const [hubsList, setHubsList] = useState([])
  const [loader, setLoader] = useState(false);

  const context = useContext(ModalContext)
  const { handleModalData } = context;


  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const getHubsData = async () => {
    setLoader(true)
    try {
      const result = await getHubs();
      setLoader(false)
      const _hubList = result.res;
      setHubsList(_hubList)
    }
    catch (e) {
      setLoader(false)
      toast.error(e.message);
    }
  }

  const ADD_NEW_HUB = <HubsForm
    title="Add New Hub"
    btnText="ADD HUB"
    getMembers={fetchMembers}
    submitHandler={submitHub}
    // setData={setHubsList}
    refreshDataMethod={getHubsData}
  />

  const ADD_REGION = <AddRegion
    setRegionData={setRegionData}
    title="Add Region"
    btnText="ADD REGION"
  />

  const ADD_COUNTRY = <AddCountry
    setCountryData={setCountryData}
    title="Add Country"
    btnText="ADD COUNTRY"
  />

  const ADD_AFFILIATION = <AffiliationForm title="ADD AFFILIATION" setAffiliationData={setAffiliationData} />

  const ADD_EXPERTISE = <AddExpertise
    title="ADD EXPERTISE"
    btnText="ADD EXPERTISE"
    setExpertiseData={setExpertiseData}
  />




  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(ADD_NEW_HUB);
        break;

      case 1:
        handleModalData(ADD_REGION, 'md');
        break;

      case 2:
        handleModalData(ADD_COUNTRY, 'md');
        break;

      case 3:
        handleModalData(ADD_AFFILIATION);
        break;

      case 4:
        handleModalData(ADD_EXPERTISE, 'md');
        break;

      default:
        handleModalData(ADD_NEW_HUB);
        break;
    }
  }

  


  const tabsData = [
    {
      label: "hubs",
      data: <HubsRouting
        hubsList={hubsList}
        setHubsList={setHubsList}
      />
    },

    {
      label: "regions",
      data: <Regions
        regionData={regionData}
        setRegionData={setRegionData}
      />
    },

    {
      label: "country",
      data: <Country
        setCountryData={setCountryData}
        countryData={countryData}
      />
    },

    {
      label: "affiliation",
      data: <Affiliation
        setAffiliationData={setAffiliationData}
        affiliationData={affiliationData}
      />
    },

    {
      label: "area of expertise",
      data: <Expertise
        setExpertiseData={setExpertiseData}
        expertiseData={expertiseData}
      />
    }
  ]





  return (
    <>
      <Loader loader={loader} />
      <div className="relative">
        <InnerHeader
          tabsData={tabsData}
          tabsValue={tabsValue}
          handlerTabs={handlerTabs}
          a11yProps={a11yProps}
          handleModalForm={handleModalForm}
          formBtn
        />

        {
          tabsData.map((obj, index) => (
            <TabPanel
            key={index}
              className="px-10"
              value={tabsValue}
              index={index}
            >
              {obj.data}
            </TabPanel>
          ))
        }
      </div>
    </>
  );
}
export default Master;

