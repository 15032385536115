import { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AntSwitch from '../../Common/AntSwitch'
import { TextareaAutosize } from '@material-ui/core';
import { toast } from 'react-toastify';
import { getPartyData } from '../../Master/getRequests';
import { ModalContext } from '../../../Context';
import { addAffiliation, deleteAffiliation, getSingleAffiliation, editAffiliation, allUsers, BASE_URL_IMG } from '../../../Utils/services';
import { Button } from '@material-ui/core';
import RemoveConfirmation from '../RemoveConfirmation';
import modalTextsFile from '../modalTextsFile';
import { useEffect } from 'react';
import Loader from "../../Common/Loader"
import { Autocomplete } from '@material-ui/lab';


const Form = ({
    title,
    btnText,
    type,
    setAffiliationData,
    edit,
    id,
    perticularData
}) => {
    const [isMember, setIsMember] = useState(perticularData && perticularData.LI !== undefined ? perticularData.LI : false);
    const [data, setData] = useState({
        name: perticularData && perticularData.name,
        description: perticularData && perticularData.description,
        twitter: perticularData && perticularData.twitter,
        instagram: perticularData && perticularData.instagram,
        facebook: perticularData && perticularData.facebook,
        media: perticularData && perticularData.logo,
        website: perticularData && perticularData.website,
        headquarters: perticularData && perticularData.headquarters,
        youtube: perticularData && perticularData.youtube
    });
    const [fileName, setFileName] = useState(perticularData ? perticularData.logo : '');
    const [loader, setLoader] = useState(false);
    const [officers, setOfficers] = useState(perticularData && perticularData.internationalOfficer);
    const [officersData, setOfficersData] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal, handleModalData } = context;

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const interNationalOfficersApi = async () => {
        try {
            const result = await allUsers();
            if (result.res.internationalOfficers.length > 0) {
                setOfficersData(result.res.internationalOfficers.sort(dynamicSort("name")));
            }
        }
        catch (error) {
            setLoader(false)
            //console.log(error, "Error");
            toast.error(error.message);
        }
    }

    useEffect(() => {
        interNationalOfficersApi();
    }, []);

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'officers') {
            setOfficers({
                ...officers,
                [name]: newInputValue
            })
        }
        else {
            setOfficers({
                ...officers,
                [_name]: _value
            })
        }
    }


    const delRequest = async () => {
        setLoader(true)
        let data = {
            "id": id
        }
        try {
            const result = await deleteAffiliation(data);

            if (result.res.success) {
                const _data = await getPartyData();
                setAffiliationData(_data);
                toast.success("Affiliation Deleted!");
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.message);
        }
    }

    const REMOVE_CONFIRMATION = <RemoveConfirmation
        title="REMOVE AFFILIATION"
        data={modalTextsFile.affiliationRemoveText}
        action={delRequest}
        id={id}
    />


    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setFileName(val.name);
                    setData({ ...data, "media": val })
                }
                else {
                    setFileName("");
                    setData({ ...data, "media": "" })
                }
            }
        }
    }

    const handleChange = (event) => {
        setIsMember(!isMember)
    };

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        setData({
            ...data,
            [name]: value
        })
    }


    const addAffiliationHandler = async () => {
        setLoader(true)
        let formdata = new FormData();
        const _officers = [];
        if (officers && officers.officers && officers.officers.length > 0) {
            officers.officers.map((item) => {
                _officers.push(item._id)
                // formdata.append("internationalOfficer", item._id);
            })
        }
        else {
            if (officers && officers.length > 0) {
                officers.map((item) => {
                    _officers.push(item._id)
                })
            }
        }

        if (_officers && _officers.length > 0) {
            formdata.append("internationalOfficer", _officers);
        }
        formdata.append("name", data.name);
        formdata.append("description", data.description);
        formdata.append("category", type);
        formdata.append("headquarters", data.headquarters);
        formdata.append("website", data.website);
        formdata.append("twitter", data.twitter);
        formdata.append("instagram", data.instagram);
        if (typeof data.media != "string") {
            formdata.append("media", data.media);
        }
        formdata.append("facebook", data.facebook);
        formdata.append("youtube", data.youtube);
        formdata.append("LI", isMember);
        if (edit) {
            formdata.append("id", id);
        }
        try {

            const result = edit ? await editAffiliation(formdata) : await addAffiliation(formdata);

            if (result.res.success) {
                const _data = await getPartyData();
                setAffiliationData(_data);
                toast.success(result.res.message);
                setLoader(false)
                closeModal();
            }
        }
        catch (error) {
            setLoader(false)
            //console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        if (id) {
            (
                async () => {
                    const result = await getSingleAffiliation(id);
                    const final = result.res.data.party;
                    setIsMember(final.LI);
                    const oldData = {};
                    for (let x in final) {
                        if (x !== "LI" && x !== "logo") oldData[x] = final[x];
                        if (x == "logo") oldData['media'] = final[x];
                    }
                    setData(oldData);
                }
            )();
        }
    }, [])


    return (
        <>
            <Loader loader={loader} />
            {
                title && (
                    <div className="flex items-center justify-center">
                        <h3 className="title"> {title} </h3>
                    </div>
                )
            }

            <form noValidate autoComplete="off" className="w-full" >
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">

                    <TextField
                        label="Name"
                        name="name"
                        value={data.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />

                    <TextField
                        label="Headquarters"
                        name="headquarters"
                        value={data.headquarters || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <Autocomplete
                        multiple
                        limitTags={2}
                        name="officers"
                        defaultValue={perticularData && perticularData.internationalOfficer}
                        options={officersData}
                        getOptionLabel={(option) => option.name}
                        value={officers && officers.name}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'officers')}
                        renderInput={(params) => <TextField {...params} id="badge" label="Name Of The International Officers" />}
                    />
                    <TextareaAutosize
                        label="Enter Party Description"
                        placeholder="Enter Party Description"
                        name="description"
                        value={data.description || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label="Website"
                        name="website"
                        value={data.website || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <div>
                        <div className="relative w-auto overflow-hidden">
                            {
                                edit && typeof data.media === "string" &&
                                <img src={`${BASE_URL_IMG}${data.media}`} className="mb-1" style={{ maxWidth: "50px" }} />
                            }
                            <TextField
                                id="profilephoto"
                                label="Add a Party Logo"
                                value={fileName}
                                className="w-full text-xs"
                            />
                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="logo"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800">Note: Image dimensions should be 400x400px </p>
                    </div>
                    <div>
                        <div className='flex items-baseline'>
                            <span>@</span>
                            <TextField
                                label="Twitter Handle"
                                name="twitter"
                                value={data.twitter || ''}
                                onChange={e => onChangeHandler(e)}
                                className="w-full"
                            />
                        </div>
                        <p className='mt-2'>Please Enter Your Twitter ID, Don't use Complete URL</p>
                    </div>
                    <div>
                        <div className='flex items-baseline'>
                            <span>@</span>
                            <TextField
                                label="Instagram Handle"
                                name="instagram"
                                value={data.instagram || ''}
                                onChange={e => onChangeHandler(e)}
                                className="w-full"
                            />
                        </div>
                        <p className='mt-2'>Please Enter Your Instagram ID, Don't use Complete URL</p>
                    </div>
                    <TextField
                        label="Facebook Handle"
                        name="facebook"
                        value={data.facebook || ''}
                        onChange={e => onChangeHandler(e)}
                    />

                    <TextField
                        label="YouTube Handle"
                        name="youtube"
                        value={data.youtube || ''}
                        onChange={e => onChangeHandler(e)}
                    />


                    <div className="mt-4 w-5/12">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item> Li Member </Grid>
                            <Grid item>
                                <AntSwitch
                                    checked={isMember}
                                    onChange={handleChange}
                                    name="isMember"
                                />
                            </Grid>
                        </Grid>
                    </div>


                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="button"
                        // disabled={data.name === "" || data.description === "" || data.logo === "" || data.instagram === "" || data.youtube === "" || data.headquarters === "" || data.website === "" || data.twitter === "" || data.facebook === ""}
                        disabled={loader ? true : false}
                        onClick={addAffiliationHandler}>
                        {btnText}
                    </button>
                </div>
                {
                    edit && (
                        <div className="flex justify-center mt-5">
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="approve_decline_padding"
                                onClick={() => handleModalData(REMOVE_CONFIRMATION, 'sm')}
                                disabled={loader ? true : false}
                            >
                                <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                <span className="pl-1">Remove</span>
                            </Button>
                        </div>
                    )
                }
            </form>
        </>
    )
}

export default Form;