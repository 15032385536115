
export const feedsData = [
      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },
      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },

      {
        col1: 'flag',
        col2: {img:'../assets/pp.png',name:'Astrid Thors'},
        col3: '../assets/dummy.jpg',
        col4: 'Common',
        col5: 'DD/MM/YYYY',
        col6: '2200',
        col7: '1227',
        views: '10007',
        statistics:'10%, 20%, 30%'
      },
    ];



export const postView = [
  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  },

  {
      img: '/assets/icons/pp.png',
      text: 'Isabelle Pucher'
  }
]