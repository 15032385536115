import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Loader from "../Common/Loader"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextareaAutosize } from '@material-ui/core';
import 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { addEvent, getHubs, getMinihubs } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import timezoneList from "./timezone.json"

const AddEventForm = ({
    title,
    buttonText,
    getEventApiData,
    eventAllData
}) => {
    const [loader, setLoader] = useState(false);
    const [badges, setBadges] = useState([]);
    const [showMinihubList, setShowMinihubList] = useState(false)
    const [hubsData, setHubsData] = useState([]);

    const [allFieldData, setAllFieldData] = useState({
        name: '',
        postTo: title === "Add LI Event" ? "li" : "minihub" || '',
        repeat: '',
        description: '',
    })
    const [links, setLinks] = useState([]);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [allMinihubs, setallMinihubs] = useState({
        minihubs: [],
    })
    const [allhubs, setallHubs] = useState({
        hubs: [],
    })
    const [allTimezone, setallTimezone] = useState({
        timezone: [],
    })

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const formateTime = (time) => {
        const _Time = new Date(time);
        const hours = _Time.getHours();
        const minutes = _Time.getMinutes();
        // const hours = _Time.getUTCHours();
        // const minutes = _Time.getUTCMinutes();
        const FullTime = `${hours}:${minutes}`
        return FullTime
    }

    //console.log(eventAllData, "eventAllData")

    const getHubsData = async () => {
        try {
            const result2 = await getHubs();
            const _HubLists = result2.res;
            setHubsData(_HubLists)
        }
        catch (error) {
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getHubsData();
    }, [])


    const onInputHubChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'hubs') {
            setallHubs({
                ...allhubs,
                [name]: newInputValue
            })
        }
        else {
            setallHubs({
                ...allhubs,
                [_name]: _value
            })
        }

    }
    const onInputTimezoneChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'timezone') {
            setallTimezone({
                ...allTimezone,
                [name]: newInputValue
            })
        }
        else {
            setallTimezone({
                ...allTimezone,
                [_name]: _value
            })
        }
    }

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'minihubs') {
            setallMinihubs({
                ...allMinihubs,
                [name]: newInputValue
            })
        }
        else {
            setallMinihubs({
                ...allMinihubs,
                [_name]: _value
            })
        }
    }

    //console.log(allhubs.hubs._id, "allhubs.hubs Id ")


    const getMinihubsData = async (_id) => {
        try {
            const result2 = await getMinihubs(_id);
            const _HubList = result2.res;
            setBadges(_HubList)
        }
        catch (error) {
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        if (allhubs.hubs._id !== undefined) {
            getMinihubsData(allhubs.hubs._id);
        }
    }, [allhubs.hubs])

    const options = [
        { label: 'Every Day', value: "day", id: 1 },
        { label: 'Every Month', value: "month", id: 2 },
        { label: 'Every Year', value: "year", id: 3 },
        { label: 'Never', value: "never", id: 4 },
    ];

    const handleChange = (event, newInputValue, name) => {
        const _name = event.target.name;
        const _value = event.target.value;
        if (name == "repeat") {
            setAllFieldData({
                ...allFieldData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldData({
                ...allFieldData,
                [_name]: _value
            })
        }
    }

    const handleSubmit = async (e) => {
        setLoader(true)
        const year = start.getFullYear();
        const month = start.getMonth() + 1;
        e.preventDefault();
        const minihubsSend = allMinihubs.minihubs.map(data => {
            return data.id
        })
        try {
            const _NewLinks = links.length > 0 && links.split(",");
            const results = await addEvent(allFieldData.postTo === "minihub" ?
                {
                    "name": allFieldData.name,
                    "description": allFieldData.description,
                    // "repeat": allFieldData.repeat.value,
                    "postTo": allFieldData.postTo,
                    // "startTime": formateTime(startTime),
                    "startTime": startTime,
                    "endTime": endTime,
                    "start": start,
                    "end": end,
                    "links":  _NewLinks.length > 0 ? _NewLinks : links,
                    "minihub": minihubsSend,
                    "timezone": allTimezone.timezone.text
                } :
                {
                    "name": allFieldData.name,
                    "description": allFieldData.description,
                    // "repeat": allFieldData.repeat.value,
                    "postTo": allFieldData.postTo,
                    "startTime": startTime,
                    "endTime": endTime,
                    // "endTime": formateTime(endTime),
                    "start": start,
                    "end": end,
                    "links": _NewLinks.length > 0 ? _NewLinks : links,
                    "timezone": allTimezone.timezone.text
                }
            );
            if (results.res.success) {
                toast.success(results.res.message);
                closeModal();
                getEventApiData(year, month);
                setLoader(false);
            }
        }
        catch (error) {
            toast.error(e.messages);
            setLoader(false);

        }
    }
    useEffect(() => {
        if (title === "Add MINI HUB Event") {
            setShowMinihubList(true)
        }
        else if (title === "Add Event") {
            setShowMinihubList(true)
        }
        else if (allFieldData.postTo === "li") {
            setShowMinihubList(false)
        }
        else {
            setShowMinihubList(false)
        }
    }, [title])

    //console.log(allTimezone.timezone, "allTimezone.timezone")
    //console.log(allhubs.hubs, "Hubs")



    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full">
                {
                    title === "Add Event" &&
                    <div className="mt-4 w-6/12 flex flex-row items-center" >
                        <h1 className="text-paraText">Post To</h1>
                        <RadioGroup
                            aria-label="event"
                            defaultValue="LI Calendar"
                            name="postTo"
                            className="radio-group"
                            value={allFieldData.postTo}
                            onChange={handleChange}
                        >
                            <span className="ml-8">
                                <FormControlLabel value="li" className="text-muteText radio-label" control={<Radio color="default" />} label="LI Calendar" />
                                <FormControlLabel value="minihub" className="text-muteText radio-label" control={<Radio color="default" />} label="Mini Hub" />
                            </span>
                        </RadioGroup>
                    </div>
                }
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        id="eventName"
                        label="Event Name"
                        name="name"
                        value={allFieldData.name}
                        onChange={handleChange}

                    />
                    <Autocomplete
                        name="timezone"
                        options={timezoneList}
                        getOptionLabel={(option) => option.text}
                        value={allTimezone.timezone}
                        onChange={(e, newVal) => onInputTimezoneChange(e, newVal, 'timezone')}
                        renderInput={(params) => <TextField {...params} id="timezone" label="Select Timezone" />}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label="Start Time"
                            name="time"
                            value={startTime}
                            minutesStep={5}
                            ampm={false}
                            onChange={setStartTime}
                        />
                        <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label="End Time"
                            name="time"
                            value={endTime}
                            minutesStep={5}
                            ampm={false}
                            onChange={setEndTime}
                        />

                        <KeyboardDatePicker
                            label="Event Start Date"
                            placeholder={`${start}`}
                            value={start}
                            onChange={date => (setStart(date), setEnd(date))}
                            format={'dd-MM-yyyy'}
                            disablePast={true}
                        />
                        <KeyboardDatePicker
                            label="Event End Date"
                            placeholder={`${end}`}
                            value={end}
                            onChange={date => setEnd(date)}
                            format={'dd-MM-yyyy'}
                            disablePast={true}
                            minDate ={start}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="otherLink"
                        label="Add Other Links"
                        name="links"
                        value={links}
                        placeholder="Use HTTPS URLS"
                        onChange={(e) => setLinks(e.target.value)}
                    />
                    {/* <Autocomplete
                        name="repeat"
                        options={options}
                        getOptionLabel={(option) => option.label}
                        value={allFieldData.repeat}
                        onChange={(e, newVal) => handleChange(e, newVal, 'repeat')}
                        renderInput={(params) => <TextField {...params} id="repeat" label="repeat" />}
                    /> */}
                    {
                        showMinihubList && allFieldData.postTo === "minihub" && allhubs?.hubs?.[0] && (
                            <>
                                <Autocomplete
                                    name="hubs"
                                    options={hubsData}
                                    getOptionLabel={(option) => option.name}
                                    value={allhubs.hubs}
                                    onChange={(e, newVal) => onInputHubChange(e, newVal, 'hubs')}
                                    renderInput={(params) => <TextField {...params} id="hubs" label="Add Hubs" />}
                                />
                                <Autocomplete
                                    multiple
                                    name="minihubs"
                                    limitTags={2}
                                    options={badges}
                                    getOptionLabel={(option) => option.name}
                                    value={allMinihubs.minihubs}
                                    onChange={(e, newVal) => onInputChange(e, newVal, 'minihubs')}
                                    renderInput={(params) => <TextField {...params} id="minihubs" label="Add Minihubs" />}
                                />
                            </>
                        )
                    }
                    <TextareaAutosize
                        name="description"
                        id="eventDescription"
                        aria-label="Empty"
                        placeholder="Enter Event Description"
                        minRows={5}
                        value={allFieldData.description}
                        onChange={handleChange}
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" onClick={handleSubmit} disabled={loader ? true : false}>
                        {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddEventForm;