import React, { useState, useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import UsersTable from './UserTable';
import crossRedIcon from "../../Assets/images/icons/cross-red.svg";




const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));


const HubsMinihubsNotification = ({ data, getTablesData }) => {

    useEffect(() => {
        getTablesData();
    }, [])

    let { path } = useRouteMatch();
    const history = useHistory();
    const param = useParams();
    //console.log(param, "Param")


    const adminColumns = useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Designation',
                accessor: 'designation',
            },
            {
                Header: 'Target Group',
                accessor: 'Target Group',
            },
            {
                Header: 'Party',
                accessor: 'party',
            },
            {
                Header: 'Image',
                accessor: 'Image'
            },
            {
                Header: "Actions",
                accessor: 'badge',
                Cell: ({ cell: { value } }) => (
                    <div className='flex items-center'>
                        <span>
                            <button
                                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                type="button"
                            >
                                <span className="MuiButton-label">View</span>
                            </button>
                        </span>
                        <span className='px-2'>
                            <button
                                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                type="button"
                            >
                                <span className="MuiButton-label">RESEND</span>
                            </button>
                        </span>
                        <span className='border-2 p-1.5 border-red-600 border-solid'>
                            <img src={crossRedIcon} alt="" />
                        </span>
                    </div>
                )
            }
        ], [])

    return (
        <>
            <div className="flex justify-between items-center">
                <div>
                    <Pagination count={10} variant="outlined" shape="rounded" />
                </div>
                <div className="bg-white p-2 flex items-center search-wrapper">
                    <span>
                        <img src="/assets/icons/search.svg" />
                    </span>
                    <input type="text" placeholder="Search" className="w-96 mx-3" />
                    <span>
                        <img src="/assets/icons/submit-arrow.svg" />
                    </span>
                </div>
            </div>
            {
                data && (
                    <UsersTable columns={adminColumns} data={data} />
                )
            }
        </>
    )
}

export default HubsMinihubsNotification;