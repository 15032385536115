import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useHistory } from 'react-router';
import twitterImg from "../../Assets/images/icons/twitter.svg"
import facebookImg from "../../Assets/images/icons/facebook.svg"
import instagramImg from "../../Assets/images/icons/instagram.svg"
import youtubeImg from "../../Assets/images/icons/youtube.svg"
import { ModalContext } from '../../Context';
import AdminDetailEditForm from '../ModalTemplate/AdminDetailEditForm';
import { BASE_URL_IMG, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';

const AdminViewPage = () => {
    const [adminDetails, setAdminDetails] = useState([]);
    const [newAccessTo, setNewAccessTo] = useState([]);
    const history = useHistory();
    const location = useLocation();

    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { id } = location.state && location.state;

    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            setAdminDetails(result.data)
            result.data.accessTo.forEach((value) => {
                let arr = [];
                if (value === 1) {
                    arr.push("people")
                }
                else if (value === 2) {
                    arr.push("feeds")
                }
                else if (value === 3) {
                    arr.push("flagged")
                }
                else if (value === 4) {
                    arr.push("master")
                }
                else if (value === 5) {
                    arr.push("events")
                }
                else if (value === 6) {
                    arr.push("request")
                }
                else {
                    arr.push([]);
                }

                setNewAccessTo(data => [...data, arr])
            });

        } catch (error) {
            //console.log(error)
            // toast.error(error.message);
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    const handleAdminPush = () => {
        history.goBack();
    }

    const reportHandler = () => {
        //console.log('reportHandler');
    }
    const showPostDetail = adminDetails => {
        const POST_MODAL = <AdminDetailEditForm
            userAllData={adminDetails}
            reportHandler={reportHandler}
            isReportBtn={true}
            isModalView={true}
            getAdminDetailsApiData={getAdminDetailsApiData}
        />
        handleModalData(POST_MODAL)
    }

    return (
        <div className="container px-8">
            <div className="flex items-center mb-4">
                <span onClick={handleAdminPush} className="text-sm text-minihubPara cursor-pointer">Admin</span>
                <span className="px-2 text-sm">/</span>
                <span className="text-sidenav capitalize text-sm">{adminDetails.name}</span>
            </div>
            <div className="user_data_admin">
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-center flex-col items-center">
                                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-shadowImg imageMarginTop">
                                    <img src={`${BASE_URL_IMG}${adminDetails.image}?${new Date().getTime()}`} alt="User-image" className="w-24 h-24 rounded-full" />
                                </div>
                                <h3 className="text-sidenav capitalize mt-2 text-3xl">{adminDetails.name}</h3>
                                <p className="text-2xl text-paraText border-b border-muteText pb-2">{adminDetails.designation}</p>
                                <p className="text-lg pt-2 capitalize text-muteText">{`${adminDetails.country} , ${adminDetails.region}`}</p>
                            </div>
                            <div className="my-8 border-r border-muteText">
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Party</h3>
                                    <div className="partyBoxShadow w-96 py-5">
                                        <div className="flex items-center px-8">
                                            <img src={`${BASE_URL_IMG}${adminDetails.partyImage}?${new Date().getTime()}`} alt="party image" className="w-16" />
                                            <p className="pl-6 text-paraText text-sm">{adminDetails.party}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Badge</h3>
                                    {
                                        adminDetails.badge && adminDetails.badge.map((data , idx) => {
                                            return (
                                                <div key={idx} className="partyBoxShadow w-96 py-5">
                                                    <div className="flex items-center px-8">
                                                        <img src={`${BASE_URL_IMG}${data.logo}`} alt="party image" className="w-16" />
                                                        <p className="pl-6 text-paraText text-sm">{data.name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">Email</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.email}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">Mobile Number</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.mobile}</span>
                                </div>
                                {/* <div className="my-2 grid grid-cols-3 gap-4">  //Never delete
                                    <h3 className="text-lg text-sidenav py-2 ">Access to Update</h3>
                                    <span className="text-lg col-span-2 text-paraText">{newAccessTo && newAccessTo.join(" , ")}</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-end">
                                <div className="mt-9">
                                    <button
                                        className="button-1"
                                        onClick={() => showPostDetail(adminDetails)}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2">Bio</h3>
                                    <p className="text-lg text-paraText">{adminDetails.bio}</p>
                                </div>
                                <div className="my-2">
                                    <h3 className="text-lg text-sidenav py-2 ">Social Media</h3>
                                    <div className="flex items-center">
                                        <img src={twitterImg} alt="twitter" className="mr-2" />
                                        <img src={facebookImg} alt="facebook" className="mx-2" />
                                        <img src={instagramImg} alt="instagram" className="mx-2" />
                                        <img src={youtubeImg} alt="youtube" className="mx-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminViewPage;
