import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { routes } from "../../routes";


const MainWrapper = ({ match, handleModalData, isModalOpen, modalData }) => {

  return (
    <>
      <Switch>
        {routes.map((route) => (
          <Route
            exact
            key={`${route.path}`}
            path={`${match.path}${route.path}`}
            render={routeProps => (
              <route.component
                modalData={modalData}
                handleModalData={handleModalData}
                isModalOpen={isModalOpen}
                {...routeProps}
              />)
            }
          />
        ))}
      </Switch>
    </>
  );
};

export default withRouter(MainWrapper);
