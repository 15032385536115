import { Button } from "@material-ui/core";
import { useState, useContext } from "react";
import EditSectionPostEditer from "../ModalTemplate/EditSectionPostEditor"
import { ModalContext } from '../../Context'
import pdfIcon from "../../Assets/fonts/pdf.svg"
import { BASE_URL_IMG } from "../../Utils/services";


const LatestPostCard = ({ fetchSectionDetails, data, removeHandler }) => {
    const [articleData] = useState(data.article)
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const handleEditResources = () => {
        const edit_resources =
            <EditSectionPostEditer
                resourcesData={data}
                fetchSectionDetails={fetchSectionDetails}
            />
        handleModalData(edit_resources)
    }
    return (
        <div className="grid grid-cols-12 mt-10 latest-post-card border-b-2 pb-5">
            <div className="col-span-3">
                <ul>
                    <li>
                        {
                            data.media && data.media.length > 0 &&
                            <div className="w-full h-48 cursor-pointer bg-pdfBg flex flex-col justify-center items-center" onClick={() => window.open(data.media.map((pdf) => pdf.link))}>
                                <img src={pdfIcon} alt="" className="h-1/2" />
                                <button className="pt-2 text-2xl text-paraText">Click To Download</button>
                            </div>
                        }
                    </li>

                    <li className="text-sm mt-4">
                        <p className="primary-color font-semibold mb-0.5"> Number of Views </p>
                        <span> {data.views != null ? data.views : ''} </span>
                    </li>

                    <li className="text-sm mt-4">
                        <p className="primary-color font-semibold mb-0.5"> Posted On</p>
                        <span> {data.date} </span>
                    </li>

                    <li className="text-sm mt-4">
                        <p className="primary-color font-semibold mb-0.5"> Posted by </p>
                        <span className="flex items-center">
                            <img src={`${BASE_URL_IMG}${data.postedBy.image}?${new Date().getTime()}`} alt="" className="rounded-icon mr-2" />
                            {data.postedBy.name}
                        </span>
                    </li>
                </ul>
            </div>

            <div className="col-span-9 pl-8">
                <p className="primary-color font-semibold mb-0.5"> {data.heading} </p>
                <div className="text-wrapper text-sm">
                    {/* <p> {data.article} </p> */}
                    <div dangerouslySetInnerHTML={{ __html: articleData }}></div>
                </div>

                <div className="flex items-center mt-5">
                    <div className="mr-4">
                        <Button className="btn2 medium" onClick={handleEditResources}>
                            <span className="minus" />
                            EDIT
                        </Button>
                    </div>

                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={removeHandler}
                        className="approve_decline_padding danger-bg ml-5"
                    >
                        <span className="cross-icon" />
                        <span className="pl-1">Remove</span>
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default LatestPostCard