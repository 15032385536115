import React, { useState, useContext, useEffect } from 'react';
import UsersTable from './UsersTable';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BASE_URL_IMG, InviteAllPeopleNotification, InviteNotification } from '../../Utils/services';
import { Button } from '@material-ui/core';
import DummyDpImg from "../../Assets/images/DummyDp.png"



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const Admin = ({ data, getAdminTableData }) => {
  const [searchValue, setSearchValue] = useState("");

  let { path } = useRouteMatch();
  const history = useHistory();
  const param = useParams();
  //console.log(param, "Param")

  useEffect(() => {
    getAdminTableData()
  }, [])

  const showPostDetail = item => {
    history.push({
      pathname: `${path}/${item._id}`,
      state: {
        id: item._id,
      }

    })
  }

  const handleInvite = async (data) => {
    //console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "admin",
        id: data.row.original._id
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleInviteAll = async () => {
    try {
      const result = await InviteAllPeopleNotification({
        people: "admin",
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const adminColumns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },
      {
        Header: 'Users',
        accessor: userData => (
          <div className="flex justify-start items-center">
            {
              userData.image && <img src={`${BASE_URL_IMG}${userData.image}?${new Date().getTime()}`} alt="" className="w-12 h-12 rounded-full" />
            }
            {
              !userData.image && <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
            }
            <span className="ml-2">{userData.name}</span>
          </div>
        ),
      },
      {
        Header: 'Title',
        accessor: 'designation',
      },
      {
        Header: 'Country, Region',
        accessor: properties => <span className='capitalize'>{properties.country + ', ' + properties.region}</span>
      },
      {
        Header: 'Party',
        accessor: 'party',
      },
      {
        Header: 'Badge',
        accessor: badgeVal => {
          return (
            <p>{badgeVal.badge.join(" , ")}</p>
          )
        }
      },
      {
        id: "questionID",
        Header: "Action",
        accessor: (data) => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              key={data.id}
              onClick={() => showPostDetail(data)}
            >
              <span className="MuiButton-label">View</span>
            </button>
          )
        },
      },
      {
        Header: "Notification",
        accessor: "",
        Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Invite </Button>
      }
    ], [])

  return (
    <>

      <div className="flex justify-between items-center">
        {/* <div>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </div> */}
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        {/* <Button className="btn2" onClick={handleInviteAll} disabled> <span className='px-2'>Invite All</span> </Button> */}
      </div>
      {
        data && <UsersTable columns={adminColumns} data={data.filter((val) => {
          if (searchValue === "") {
            return val
          }
          else if (val.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.party && val.party.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.country && val.country.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.region && val.region.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.badge.length > 0) {
            for (let i = 0; i <= (val.badge.length); i++) {
              if (val.badge[i] && val.badge[i].toLowerCase().includes(searchValue.toLowerCase())) {
                return val
              }
            }
          }
        })} />
      }
    </>
  )
}

export default Admin;