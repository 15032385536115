import React, { useState, useContext, useEffect } from "react";
import { USER_FORM, INTERNATIONAL_OFFICER_USER_FORM, EVENT_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import { getEventData } from "../../Utils/services";
import LiEventData from "./LiEvent";
import MinihubEvent from "./MinihubEvent";
import AllEvent from "./AllEvent";
import AddEventForm from "../ModalTemplate/AddEventForm";
import Loader from "../Common/Loader"

const a11yProps = (index) => {
    return {
        id: `flagged-tab-${index}`,
        'aria-controls': `flagged-tabpanel-${index}`,
    };
}

const Events = () => {
    const [tabsValue, setTabsValue] = useState(0);
    const [eventAllData, setEventAllData] = useState([]);
    const [liData, setLiData] = useState([]);
    const [minihubData, setMinihubData] = useState([]);
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const [timeData, setTimeData] = useState(new Date());
    const [loader, setLoader] = useState(true);

    const getEventApiData = async (year, month) => {
        //console.log(year, month, "YearOnt")
        try {
            const result = await getEventData({
                year,
                month
            });
            setLoader(false)
            const _data = result.res.data;
            //console.log(_data, "_data")
            if (_data.li.length || _data.minihub.length > 0) {
                setEventAllData(_data.li.concat(_data.minihub))
            }
            if (_data.li.length > 0) {
                setLiData(_data.li)
            }
            if (_data.minihub.length > 0) {
                setMinihubData(_data.minihub)
            }
        }
        catch (error) {
            setLoader(false)
            //console.log(error)
        }
    }
    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
    };

    const tabsData = [
        {
            label: "Event",
            data: (
                <>
                    <Loader loader={loader} />
                    <AllEvent getEventApiData={getEventApiData} handleModalData={handleModalData} eventAllData={eventAllData} />
                </>
            )
        },

        {
            label: "LI CALENDAR",
            data: liData && <LiEventData getEventApiData={getEventApiData} handleModalData={handleModalData} liData={liData} />
        },

        {
            label: "MINI HUB CALENDARS",
            data: minihubData && <MinihubEvent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={minihubData} />
        }
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title="Add Event" buttonText="Add Event" eventAllData ={eventAllData} />);
                break;

            case 1:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title="Add LI Event" buttonText="Add LI Event" eventAllData ={eventAllData} />);
                break;

            default:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title="Add MINI HUB Event" buttonText="Add MINI HUB Event" eventAllData ={eventAllData} />);
                break;
        }
    }


    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />

            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10"
                        value={tabsValue}
                        index={index}
                        key = {index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
};

export default Events;
