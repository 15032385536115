import PeopleImg from "../../Assets/images/request/people.png";
import { Button, Card } from "@material-ui/core";
import React from "react";
import TrueIcon from "../../Assets/images/icons/trueicon.svg"
import FalseIcon from "../../Assets/images/icons/falseicon.svg"
import { approvePeopleApi, BASE_URL_IMG, declinePeopleApi } from "../../Utils/services";
import { toast } from "react-toastify";

const People = ({ peopleData, getRequestAllData }) => {

  const handleDecline = async (id) => {
    try {
      const result = await declinePeopleApi(id);
      if (result.success) {
        toast.success(result.message)
        getRequestAllData();
      }
    } catch (error) {
      //console.log(error)
      toast.error(error)
    }
  }

  const handleApprove = async (id) => {
    try {
      const result = await approvePeopleApi(id);
      //console.log(result)
      if (result.success) {
        toast.success(result.message)
        getRequestAllData();
      }
    } catch (error) {
      //console.log(error)
      toast.error(error)
    }
  }

  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2">
      {
        peopleData && peopleData.map((data , idx) => {
          const { user, sharedBy } = data
          return (
            <Card key={idx} className="p-8">
              <div className="flex items-center">
                <img src={`${BASE_URL_IMG}${user.image}?${new Date().getTime()}`} alt="people" className="w-12 h-12 rounded-full" />
                <h3 className="text-2xl text-sidenav pl-4">{user.name}</h3>
              </div>
              <div className="pt-8">
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Email Id</h4>
                  <p className="text-paraText text-base col-span-3">{user.email}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Designation</h4>
                  <p className="text-paraText text-base col-span-3">{user.designation}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Country</h4>
                  <p className="text-paraText text-base col-span-3">{user.country}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Region</h4>
                  <p className="text-paraText text-base col-span-3">{user.region}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Hubs</h4>
                  <p className="text-paraText text-base col-span-3">{user.badge && user.badge}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Mini-Hubs</h4>
                  <p className="text-paraText text-base col-span-3">{user.minihubNames && user.minihubNames}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Party</h4>
                  <p className="text-paraText text-base col-span-3">{user.party}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Expertise</h4>
                  <p className="text-paraText text-base col-span-3">{user.expertise && user.expertise.join(" , ")}</p>
                </div>
                <div className="grid grid-cols-5 gap-4 py-2">
                  <h4 className="text-muteText text-base col-span-2">Shared by</h4>
                  <p className="text-paraText text-base col-span-3">{sharedBy.name}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 pt-5">
                <Button variant="outlined" className="approval_button_style mr-10" onClick={() => handleApprove(data._id)}>
                  <img src={TrueIcon} alt="true" />{" "}
                  <span className="pl-1">Approve</span>
                </Button>
                <Button variant="outlined" color="secondary" className="approve_decline_padding" onClick={() => handleDecline(data._id)}>
                  <img src={FalseIcon} alt="true" />{" "}
                  <span className="pl-1">Decline</span>
                </Button>
              </div>
            </Card>
          )
        })
      }
    </div>
  );
};

export default People;
