import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useLocation } from "react-router-dom";
import classNames from 'classnames';



const SimpleBreadcrumbs = () => {
    const [breadcrumbList, setBreadcrumbList] = useState([])
    const [activeLink, setActiveLink] = useState()
    const location = useLocation()
    
    useEffect(() => {
        const urlArray = location.pathname.split('/')
        const _activeLink = urlArray[urlArray.length - 1]
        setActiveLink(_activeLink)
        setBreadcrumbList(urlArray)

    }, [location])
    

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {
                    breadcrumbList.map((item , idx) => (
                        item != '' && (
                            <Link 
                            key={idx}
                                color="inherit"
                                href={`/${item}`}
                                className={classNames({
                                    'primary-color': activeLink == item
                                })}
                            >
                                { item == 'master' ? 'hub' : item }
                            </Link>
                        )
                    ))
                }
            </Breadcrumbs>
        </>
        
    );
}


export default SimpleBreadcrumbs;
