import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AntSwitch from '../Common/AntSwitch'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { toast } from 'react-toastify';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@material-ui/lab/Autocomplete';



const _helperText = {
    badge: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
    expertise: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option"
}



const AddSection = ({
    title,
    btnText,
    miniHubId,
    fetchManagers,
    submitHandler,
    refreshDataMethod,
    closeModal
}) => {
    const [managerData, setManagerData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [formData, setFormData] = useState({})


    useEffect(() => {
        _fetchManagers()
    }, [])
    

    const _fetchManagers = async () => {
        try {
            const res = await fetchManagers()
            setManagerData(res)
            // toast.success()
        } catch (error) {
            
        }
    }

    const onChangeHandler = (event) => {
        const name = event.target.name
        const value = event.target.value
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const selectHandler = (event, newValue) => {
        setSelectedData(newValue)
    }

    const clickHandler = async (e) => {
        e.preventDefault()
        const body = {
            ...formData,
            manager: selectedData._id,
            minihub: miniHubId
        }

        try {
            submitHandler(body)
            refreshDataMethod()
            closeModal()
            
        } catch (error) {
            //console.log(error);
        }
    }


    return (
        <>
            <div className="flex items-center justify-center">
                <h3 className="title"> { title } </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full">
                
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        name="name"
                        label="Name"
                        onChange={onChangeHandler}
                    />
                    
                    <TextField
                        name="description"
                        label="Enter Mini Description"
                        minRows={3}
                        onChange={onChangeHandler}
                    />

                    <Autocomplete
                        name="manager"
                        options={managerData}
                        getOptionLabel={(option) => option.name}
                        value={selectedData.name}
                        onChange={(e, newVal) => selectHandler(e, newVal)}
                        renderInput={(params) => <TextField
                            {...params}
                            id="manager"
                            label="Add Section Manager"
                            helperText={_helperText.badge}
                        />}
                    />

                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" onClick={clickHandler}>
                        { btnText }
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddSection;