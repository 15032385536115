import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
      fontFamily: [
        'ArialReg',
        'Arial',
        'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
    },
});

export default theme