import avatar from "../../Assets/images/avatar.png"
import { BASE_URL_IMG } from "../../Utils/services"


const CommentSection = ({ data }) => {
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }


    return (
        <div className="comment-section">
            {
                data && data.map((item, indx) => (
                    <div className="list-row" key={indx}>
                        <ul className="comment-lists">
                            <li>
                                <img alt="" src={`${BASE_URL_IMG}/user/${item.reportedBy}/${item.reportedBy}.jpeg?${new Date().getTime()}` || avatar} className="w-full" />
                                { item.reportedByName }
                            </li>

                            <li> { formatDate(item.reportedOn) } </li>
                            <li> { item.issue } </li>
                            <li> { item.comments } </li>
                        </ul>
                    </div>
                ))
            }
        </div>
    )
}

export default CommentSection