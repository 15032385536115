import { Switch, Route } from "react-router-dom";
import { nestedRoutes } from "../../routes"
import SimpleBreadcrumbs from './SimpleBreadcrumbs';


const HubsRouting = (props) => {
    
    return (
      <>
        <Switch>
          {nestedRoutes.map((route) => (
            <Route
              key={`${route.path}`}
              path={`/${route.path}`}
              exact={true}
              render={routeProps => (
                <route.component
                  {...props}
                  {...routeProps}
                />)
              }
            />
          ))}
        </Switch>
      </>
    ) 
}

export default HubsRouting