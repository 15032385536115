import post from "../../Assets/images/post1.png"
import avatar from "../../Assets/images/avatar.png"
import classNames from "classnames"
import ReactPlayer from 'react-player'
import { BASE_URL_IMG } from "../../Utils/services"


const VideoPlayer = ({
    avatarClass,
    data,
    isModalView,
    isReportBtn,
    onClickHandler
 }) => {

    const renderYoutubeVideo = link => {
        return (
            <ReactPlayer
              url={link}
              controls={true}
              width="100%"
              loop={true}
            />
        )
    }

    return (
        <div className={classNames({
            'post-detail': true,
            'relative': true,
            'modal-view': isModalView
        })}>
            <>
                <div className="header">
                    <img alt="" src={`${BASE_URL_IMG}${data.postedBy.image}?${new Date().getTime()}` } className={avatarClass} />
                    <span className="pt-1 text-sm primary-color font-semibold tracking-wider">
                        { data.postedBy.name || '' }
                    </span>
                </div>

                <div className="content">
                    { renderYoutubeVideo(data.media[0].link) }

                    {
                    !isModalView && (
                        <span className="text-btn2" onClick={() => onClickHandler(data)}>
                            Read Article
                        </span>
                    ) 
                }
                    
                    {
                        isReportBtn && (
                            <div className="flex pt-4">
                                <button
                                    className="button-1"
                                    type="button"
                                    // disabled ={loader ? true : false}
                                    // onClick={edit ? handleEdit : handleAdd}
                                >
                                    Report
                                </button>
                            </div>
                        )
                    }
                </div>
                <div className="footer bg-white p-2">
                    <h3 className="title text-sm font-semibold tracking-wider mb-1"> { data.title } </h3>
                    <p className="text text-sm">
                        { data.caption }
                    </p>
                </div>
            </>
        </div>
    )
}

export default VideoPlayer