import { Checkbox, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextareaAutosize, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import { EditAdmin, getHubs, partyList, regionCountryList } from '../../Utils/services';
import Loader from "../Common/Loader"

const _helperText = {
    badge: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
}


const AdminDetailEditForm = ({ userAllData, getAdminDetailsApiData }) => {
    const [badges, setBadges] = useState([]);
    const [name, setName] = useState(userAllData.name);
    const [email, setEmail] = useState(userAllData.email);
    const [number, setNumber] = useState(userAllData.mobile || "");
    const [designation, setDesignation] = useState(userAllData.designation);
    const [country, setCountry] = useState(userAllData.country);
    const [region, setRegion] = useState(userAllData.region);
    const [fileName, setFileName] = useState("");
    const [people, setPeople] = useState(false);
    const [feeds, setFeeds] = useState(false);
    const [flagged, setFlagged] = useState(false);
    const [master, setMAster] = useState(false);
    const [events, setEvents] = useState(false);
    const [request, setRequest] = useState(false);
    const [partyListData, setPartyListData] = useState([]);
    const [party, setParty] = useState({});
    const [loader, setLoader] = useState(false);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(userAllData.isSuperAdmin);
    const [formData, setFormData] = useState(userAllData);

    const [allFieldsData, setAllFieldsData] = useState({
        members: userAllData.badge.map((item) => {
            return {
                _id: item._id,
                name: item.name
            }
        }),
    })

    const context = useContext(ModalContext);
    const { closeModal } = context;

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }


    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            if (result.res.data.country.length > 0 && result.res.data.region.length > 0) {
                // //console.log(result)
                // const regionData = [];
                // result.res.data.region.map((obj) => {
                //     regionData.push(obj.name);
                // })
                // setRegions(regionData);
                setRegions(result.res.data.region.sort(dynamicSort("name")));
                // setCountries(result.res.data.country);
            }
        }
        catch (error) {
            //console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    const handleRegionData = async (val) => {
        setRegion(val);
        try {
            const result = await regionCountryList();
            const _allCountry = result.res.data.country;
            const countriesData = _allCountry.filter(obj => {
                return obj.region.toLowerCase() == val.toLowerCase()
            }
            )
            setCountries(countriesData && countriesData.sort(dynamicSort("name")));
        }
        catch (error) {
            //console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getCountryData();
    }, []);


    const onInputPartyChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'party') {
            setParty({
                ...party,
                [name]: newInputValue
            })
        }
        else {
            setParty({
                ...party,
                [_name]: _value
            })
        }
    }

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'members') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }

    const getParty = async () => {
        try {
            const response = await partyList();
            const _partyList = response.res.data.party;
            setPartyListData(_partyList && _partyList.sort(dynamicSort("name")))
            //console.log(response.res, "party get data")

        }
        catch (error) {
            //console.log(error);
            toast.warning("Something went wrong!")
        }
    }


    const getBadgeData = async () => {
        try {
            const result2 = await getHubs();
            const _HubList = result2.res;
            //console.log(_HubList, '_HubList')
            setBadges(_HubList && _HubList.sort(dynamicSort("name")))
        }
        catch (error) {
            //console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getBadgeData();
        getParty();
    }, []);

    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setFileName(val);
                }
                else {
                    setFileName("");
                }
            }
        }
        // setFileName(val.name);
        //console.log(fileName, "fileName")
    }


    const handleEditSubmit = async (e) => {
        const arr = [];
        if (people == true) {
            arr.push(1)
        }
        if (feeds == true) {
            arr.push(2)
        }
        if (flagged == true) {
            arr.push(3)
        }
        if (master == true) {
            arr.push(4)
        }
        if (events == true) {
            arr.push(5)
        }
        if (request == true) {
            arr.push(6)
        }

        setLoader(true)
        e.preventDefault();
        const badgesValueSend = allFieldsData.members.map(data => {
            return data._id
        })
        try {
            const formData = new FormData();
            formData.append('id', userAllData._id);
            if (userAllData.name !== name) {
                formData.append('name', name);
            }
            if (userAllData.email !== email) {
                formData.append('email', email && email.toLowerCase());
            }
            if (userAllData.number !== number) {
                formData.append('mobile', number);
            }
            if (userAllData.designation !== designation) {
                formData.append('designation', designation);
            }
            if (userAllData.party !== undefined) {
                formData.append('party', party.party !== undefined ? party.party._id : userAllData.partyId);
            }
            if (country) {
                formData.append('country', country);
            }
            if (region) {
                formData.append('region', region);
            }
            if (fileName) {
                formData.append("media", fileName);
            }
            if (badgesValueSend.length > 0) {
                formData.append('badge', badgesValueSend);
            }
            formData.append('update', people);
            formData.append('feeds', feeds);
            formData.append('flagged', flagged);
            formData.append('master', master);
            formData.append('events', events);
            formData.append('request', request);
            formData.append("accessTo", arr);
            formData.append("isSuperAdmin", isSuperAdmin);

            const result = await EditAdmin(formData);
            if (result.res.success) {
                setLoader(true)
                toast.success(result.res.message);
                setLoader(false)
                closeModal()
                getAdminDetailsApiData();
                //console.log(result)
            }
        } catch (error) {
            setLoader(false)
            //console.log(error)
        }
    }

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
        if (value === "superAdmin") {
            setIsSuperAdmin(true)
        }
        else {
            setIsSuperAdmin(false)
        }
    }





    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> EDIT ADMIN </h3>
            </div>
            <form className="w-full" onSubmit={handleEditSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        required
                        name="name"
                        id="name"
                        label="Name"
                        className="w-full"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        required
                        name="email"
                        type="email"
                        id="email"
                        label="Email id"
                        className="w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        name="number"
                        id="number"
                        label="Mobile Number"
                        className="w-full"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <TextField
                        name="designation"
                        id="designation"
                        label="Title"
                        className="w-full"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                    />
                    <div>
                        <div className="relative w-auto overflow-hidden">
                            <TextField
                                id="profilephoto"
                                label="Add a Profile Photo"
                                className="w-full text-xs"
                                value={fileName.name || ""}
                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="profile-photo"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800 mt-2">Note: Image dimensions should be 400x400px </p>
                    </div>
                    <Autocomplete
                        name="party"
                        options={partyListData}
                        getOptionLabel={(option) => option.name}
                        value={party.name}
                        onChange={(e, newVal) => onInputPartyChange(e, newVal, 'party')}
                        renderInput={(params) => <TextField required {...params} id="party" label="Name of the Party/Organisation/Thinktank" />}
                    />
                    <Autocomplete
                        id="combo-box-demo1"
                        options={regions}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        inputValue={region}
                        onInputChange={(event, newInputValue) => handleRegionData(newInputValue)}
                        renderInput={(params) => <TextField {...params} label="Region" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        sx={{ width: 300 }}
                        inputValue={country}
                        onInputChange={(event, newInputValue) => setCountry(newInputValue)}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    <Autocomplete
                        multiple
                        limitTags={2}
                        options={badges}
                        getOptionLabel={(option) => option.name}
                        defaultValue={allFieldsData.members}
                        value={allFieldsData.members}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'members')}
                        renderInput={(params) => <TextField {...params} id="badge" label="Add a Badge" helperText={_helperText.badge} />}
                    />
                </div>
                <div className="mt-4 w-6/12">
                    <RadioGroup aria-label="accessLabel" name="accessLabel" className="radio-group" defaultValue={isSuperAdmin ? "superAdmin" : "admin"} onChange={e => onChangeHandler(e)}>
                        <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                        <FormControlLabel value="superAdmin" control={<Radio />} label="Super Admin" />
                    </RadioGroup>
                </div>
                {/* <div className="flex items-center mt-5 customeCheckbox"> // Never Delete This
                    <h3 className="mr-8 text-paraText">Access to Update</h3>
                    <FormControlLabel control={<Checkbox name="people" checked={people} onChange={(e) => setPeople(e.target.checked)} defaultChecked color="primary" />} label="People" labelPlacement="start" />
                    <FormControlLabel control={<Checkbox name="people" checked={feeds} onChange={(e) => setFeeds(e.target.checked)} color="primary" />} label="Feeds" labelPlacement="start" />
                    <FormControlLabel control={<Checkbox name="people" checked={flagged} onChange={(e) => setFlagged(e.target.checked)} color="primary" />} label="Flagged" labelPlacement="start" />
                    <FormControlLabel control={<Checkbox name="people" checked={master} onChange={(e) => setMAster(e.target.checked)} color="primary" />} label="Master" labelPlacement="start" />
                    <FormControlLabel control={<Checkbox name="people" checked={events} onChange={(e) => setEvents(e.target.checked)} color="primary" />} label="Events" labelPlacement="start" />
                    <FormControlLabel control={<Checkbox name="people" checked={request} onChange={(e) => setRequest(e.target.checked)} color="primary" />} label="Request" labelPlacement="start" />
                </div> */}
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type='submit'>
                        SAVE
                    </button>
                </div>
            </form>
        </>
    )
}

export default AdminDetailEditForm
