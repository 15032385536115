import React, { useContext } from 'react';
import { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import LinkIcon from "../../Assets/images/link.svg"
import { addResourcesApi, editResourcesApi } from '../../Utils/services';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';




const EditSectionPostEditer = ({ resourcesData, fetchSectionDetails }) => {

    // const customContentStateConverter = (contentState) => {
    //     // changes block type of images to 'atomic'
    //     const newBlockMap = contentState.getBlockMap().map((block) => {
    //         const entityKey = block.getEntityAt(0);
    //         if (entityKey !== null) {
    //             const entityBlock = contentState.getEntity(entityKey);
    //             const entityType = entityBlock.getType();
    //             switch (entityType) {
    //                 case 'IMAGE': {
    //                     const newBlock = block.merge({
    //                         type: 'atomic',
    //                         text: 'img',
    //                     });
    //                     return newBlock;
    //                 }
    //                 default:
    //                     return block;
    //             }
    //         }
    //         return block;
    //     });
    //     const newContentState = contentState.set('blockMap', newBlockMap);
    //     return newContentState;
    // }
    // const blocksFromHTML = convertFromHTML(resourcesData.article);
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(customContentStateConverter(
    //     ContentState.createFromBlockArray(
    //         blocksFromHTML.contentBlocks,
    //         blocksFromHTML.entityMap
    //     )
    // )))
    const [editorState, setEditorState] = useState(EditorState.createWithContent( ContentState.createFromBlockArray(
        convertFromHTML(resourcesData.article)
      ))) //for show all text

    const [titleHeading, setTitleHeading] = useState(resourcesData && resourcesData.heading);
    const [pdfFile, setPdfFile] = useState(resourcesData && resourcesData.files[0]);
    const [article, setArticle] = useState(resourcesData.article);
    const [mediaType, setMediaType] = useState("file")
    const [sectionId, setSectionId] = useState('');
    const location = useLocation(resourcesData && resourcesData.location);
    const context = useContext(ModalContext)
    const { closeModal } = context;

    useEffect(() => {
        const { id } = location.state;
        setSectionId(id)
    }, []);

    const handlePdfFile = (val) => {
        setPdfFile(val);
        setMediaType("file")
    }



    // react-draft-wysiwyg Start
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }


    // react-draft-wysiwyg End

    const handleSubmitSection = async (e) => {
        e.preventDefault();
        try {
            // const formData = new FormData();
            // formData.append('id', resourcesData && resourcesData._id)
            // formData.append('heading', titleHeading)
            // formData.append('article', article)
            // formData.append('media', pdfFile)
            // formData.append('mediaType', mediaType)
            const result = await editResourcesApi({
                'id' : resourcesData && resourcesData._id,
                'heading': titleHeading,
                'article': article,
                'media': pdfFile,
                'mediaType': mediaType
            });
            //console.log(result)
            if (result.res.success) {
                toast.success(result.res.message)
                closeModal();
                fetchSectionDetails();
            }
        } catch (error) {
            toast.error(error)
        }
    }

    return (
        <>
            <div className="flex items-center justify-center">
                <h3 className="title"> Write Post </h3>
            </div>
            <form className="w-full" onSubmit={handleSubmitSection}>
                <div className="grid grid-cols-1 gap-x-24 gap-y-4">
                    <input
                        name="title"
                        id="title"
                        label="Heading"
                        placeholder='Heading...'
                        className="w-full p-3 border border-muteText border-solid focus:outline-none"
                        value={titleHeading}
                        onChange={(e) => setTitleHeading(e.target.value)}
                    />
                </div>
                <div className='editorSectionStart'>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                            embedded: {
                                embedCallback: embedVideoCallBack
                            }
                        }}
                        value={article}
                    />
                </div>
                {/* <Grid item md={2}>
          <div className="relative w-auto overflow-hidden">
            <h4 className='mt-4 text-sidenav font-bold'>Add Video(s)</h4>
            <TextField
              id="profilephoto"
              label="Add a Video"
              // value={VideoFile.name || ""}
              className="w-full text-xs"
            />

            <input
              type="file"
              accept="video/*"
              className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
              name="video-file"
            // onChange={(e) => handleVideoFile(e.target.files[0])}
            />
            <div className="floating-box" >+</div>
          </div>
          <div className="media-box mt-2">
            <img src={videoIcon} alt="" />
            Videos
          </div>
        </Grid> */}
                <Grid item md={2}>
                    <div className="relative w-auto overflow-hidden">
                        <h4 className='mt-4 text-sidenav font-bold'>Attach Files</h4>
                        <TextField
                            id="profilephoto"
                            label="Add a PDF File"
                            value={pdfFile && pdfFile.name ? pdfFile.name : pdfFile || ""}
                            className="w-full text-xs"
                        />

                        <input
                            type="file"
                            accept="application/pdf, application/vnd.ms-excel"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="pdf-file"
                            onChange={(e) => handlePdfFile(e.target.files[0])}
                        />
                        <div className="floating-box" >+</div>
                    </div>
                    <div className="media-box mt-2">
                        <img src={LinkIcon} alt="" />
                        File
                    </div>
                </Grid>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type='submit'>
                        POST
                    </button>
                </div>
            </form>
        </>
    );
};

export default EditSectionPostEditer;
