import { useMemo, useState, useEffect, useContext } from "react"
import Table from '../../Common/Table'
import { Button } from "@material-ui/core";
import { getRegionData } from "../getRequests";
import AddRegion from '../../ModalTemplate/AddRegion'
import { ModalContext } from '../../../Context'
import { BASE_URL_IMG } from "../../../Utils/services";



const Regions = ({ regionData, setRegionData }) => {
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    useEffect(() => {
        (async () => {
            const data = await getRegionData();
            setRegionData(data);
        })();

    }, [])

    const editHandler = (data) => {
        const EDIT_REGION = <AddRegion
            setRegionData={setRegionData}
            title="Edit Region"
            btnText="SAVE"
            id={data.cell.row.original._id}
            name={data.cell.row.original.name}
            image={data.cell.row.original.image}
            edit
        />
        handleModalData(EDIT_REGION, 'md')
    }

    const regionColumns = useMemo(
        () => [
            {
                Header: 'Image',
                accessor: 'image', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) =>
                    <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className="w-10" />
            },

            {
                Header: 'Countries',
                accessor: 'countries',
                Cell: ({ cell: { value } }) => value.map((item , idx) =>
                     <span key={idx} className="capitalize">{`${item.name} , `}</span>
                )
            },
            {
                Header: 'Region',
                accessor: 'name',
                Cell: ({ cell: { value } }) => <span className="capitalize">{value}</span>
            },

            {
                Header: 'Added On',
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                Header: 'Added By',
                accessor: 'createdBy',
                Cell: ({ cell: { value } }) => <div className='flex items-center'><img src={`${BASE_URL_IMG}${value.image}?${new Date().getTime()}`} className=' w-10 h-10 flex object-cover rounded-full mr-2' />{value.name}</div>
            },
            {
                id: "_id",
                Header: "Action",
                accessor: "view",
                Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>,
            }
        ],
        []
    )

    return (
        <>
            {regionData &&
                <Table columns={regionColumns} data={regionData} />
            }
        </>
    )
}

export default Regions