import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './Components/App';
import './Assets/styles.css'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import theme from "./Material_UI_Theme"
import { AuthContextProvider } from './Utils/auth-context'
import NetworkServices from './Utils/network-services'


NetworkServices();
ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

