import logo from "../../Assets/images/logo.png";
// import logo from "../../Assets/images/LoginImage.png";
import { loginService, loginServiceFirst } from "../../Utils/services";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import AuthContext from '../../Utils/auth-context'
import { useHistory } from "react-router";
import firebase from "../../FirebaseFile"
import { Link } from "react-router-dom";
import { getFCMToken } from "../../Massaging";


const Login = () => {
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const [deviceToken, setDeviceToken] = useState("");
    const [isForgetActive, setForgetActive] = useState(false)
    const [userData, setUserData] = useState({
        email: "",
        password: ""
    })
    const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     if (firebase.messaging.isSupported()) {
    //         const msg = firebase.messaging();
    //         msg.requestPermission().then(() => {
    //             return msg.getToken();
    //         }).then((data) => {
    //             setDeviceToken(data)
    //             //console.log(data, "token Data")
    //         })
    //     }

    // }, [])

    useEffect(() => {
        getFCMToken()
          .then((currentToken) => {
            if (currentToken) {
              //console.log("FCM Token:", currentToken);
              setDeviceToken(currentToken);
            } else {
              //console.log("No FCM token available");
            }
          })
          .catch((error) => {
            console.error("Error getting FCM token:", error);
          });
      }, []);

    //Old

    // const loginSubmit = async (e) => {
    //     e.preventDefault()
    //     setLoading(true)
    //     try {
    //         if (userData.email && userData.password) {
    //             const data = {
    //                 name: "Admin",
    //                 email: userData.email,
    //                 password: userData.password,
    //                 fcmToken: deviceToken
    //             }
    //             const result = await loginService(data);
    //             localStorage.setItem('adminId', result.res.data.id)
    //             localStorage.setItem('deviceToken', deviceToken)
    //             toast.success(result.res.message || "User Authenticated!");
    //             authCtx.login(result.res.data.accessToken, result.res.data.refreshToken, result.res.data.isSuperAdmin);
    //             setLoading(false);
    //             history.replace("/");
    //         }
    //     }
    //     catch (error) {
    //         toast.error(error.message);
    //         setLoading(false);
    //     }
    // }

    // Old


    // New
    const loginSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (userData.email && userData.password) {
                const data = {
                    name: "Admin",
                    email: userData.email,
                    password: userData.password,
                    fcmToken: deviceToken
                }
                const result = await loginServiceFirst(data);
                if (result.res.success) {
                    setLoading(false);
                    localStorage.setItem('deviceToken', deviceToken)
                    history.push({
                        pathname: "/loginOtp",
                        state: {
                            fcmToken: deviceToken,
                            email: userData.email,
                        },
                    })
                }

            }
        }
        catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }
    // New
    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        loginSubmit(e)
    }

    return (
        <div className="w-2/5 mx-auto auth-container">
            <div className="w-1/2 mx-auto text-center">
                <img
                    src={logo}
                    className="mx-auto mb-5"
                    alt="logo"
                />

                <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                    {isForgetActive ? 'FORGET PASSWORD' : 'SIGN IN'}
                </h2>

                <form className="auth-form" onSubmit={e => handleSubmit(e)}>
                    <input
                        type="email"
                        placeholder="Email ID"
                        name="email"
                        value={userData.email}
                        onChange={(e) => handleChange(e)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={userData.password}
                        onChange={(e) => handleChange(e)}
                        required
                    />

                    {
                        loading ? <p>Loading...</p> :
                            <button
                                className="primary-btn"
                                type="submit"
                            >
                                {isForgetActive ? 'submit' : 'sign in'}
                            </button>
                    }
                    <span
                        className="text-btn"
                        onClick={() => setForgetActive(true)}
                    >
                        <Link to="/forgotpassword">Forgot Password ?</Link>
                    </span>
                </form>
            </div>
        </div>
    )
}

export default Login;