import PeopleImg from "../../Assets/images/request/people.png";
import { Button, Card } from "@material-ui/core";
import React from "react";
import TrueIcon from "../../Assets/images/icons/trueicon.svg"
import FalseIcon from "../../Assets/images/icons/falseicon.svg"
import { approveCalendarApi, approvePeopleApi, BASE_URL_IMG, declinePeopleApi } from "../../Utils/services";
import { toast } from "react-toastify";

const CalendarRequest = ({ calendarData, getRequestAllData }) => {

    const handleDecline = async (id) => {
        try {
            const result = await declinePeopleApi(id);
            if (result.success) {
                toast.success(result.message)
                getRequestAllData();
            }
        } catch (error) {
            //console.log(error)
            toast.error(error)
        }
    }

    const handleApprove = async (id) => {
        try {
            const result = await approveCalendarApi(id);
            //console.log(result)
            if (result.success) {
                toast.success(result.message)
                getRequestAllData();
            }
        } catch (error) {
            //console.log(error)
            toast.error(error)
        }
    }

    const formateTime = (time) => {
        const _Time = new Date(time);
        const hours = _Time.getHours();
        const minutes = _Time.getMinutes();
        // const hours = _Time.getUTCHours();
        // const minutes = _Time.getUTCMinutes();
        const FullTime = `${hours}:${minutes}`
        return FullTime
    }

    return (
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2">
            {
                calendarData && calendarData.map((data , idx) => {
                    const { event, sharedBy } = data
                    return (
                        <Card key={idx} className="p-8">
                            <div className="flex items-center">
                                <img src={`${BASE_URL_IMG}${event.postedBy.image}?${new Date().getTime()}`} alt="people" className="w-12 h-12 rounded-full" />
                                <h3 className="text-2xl text-sidenav pl-4">{event.postedBy.name}</h3>
                            </div>
                            <div className="pt-8">
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Event Name</h4>
                                    <p className="text-paraText text-base">{event.name}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Event Date</h4>
                                    <p className="text-paraText text-base">{`${new Date(event.start).toDateString()} to ${new Date(event.start).toDateString()}`}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Event Time</h4>
                                    <p className="text-paraText text-base">{`${formateTime(event.startTime)} to ${formateTime(event.endTime)}`}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Repeat</h4>
                                    <p className="text-paraText text-base">{event.repeat}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Mini Hub</h4>
                                    {
                                        event.minihub && event.minihub.map((_item , idx2) => {
                                            return (
                                                <div key={idx2}>
                                                    <p  className="text-paraText text-base">{_item.hubName}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Hub</h4>
                                    {
                                        <p className="text-paraText text-base">{event && event.hub && event.hub.name && event.hub.name}</p>
                                    }
                                </div>
                                <div className="grid grid-cols-2 gap-4 py-2">
                                    <h4 className="text-muteText text-base">Link</h4>
                                    {
                                        event.links && event.links.map((_item , idx3) => {
                                            return (
                                                <div key={idx3}>
                                                    <p className="text-paraText text-base">{_item}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pt-5">
                                <Button variant="outlined" className="approval_button_style mr-10" onClick={() => handleApprove(data._id)}>
                                    <img src={TrueIcon} alt="true" />{" "}
                                    <span className="pl-1">Approve</span>
                                </Button>
                                <Button variant="outlined" color="secondary" className="approve_decline_padding" onClick={() => handleDecline(data._id)}>
                                    <img src={FalseIcon} alt="true" />{" "}
                                    <span className="pl-1">Decline</span>
                                </Button>
                            </div>
                        </Card>
                    )
                })
            }
        </div>
    );
};

export default CalendarRequest;
