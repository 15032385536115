export const LOGIN = "admin-signin";
export const LOGIN_FIRST = "signin-first";
export const ADD_ADMIN = "add-admin";
export const REGION_COUNRTY_LIST = "data/countries-regions";
export const BADGES = "data/badges"
export const PARTIES = "data/party"
export const ALL_USER = "people"
export const GET_ADMIN_DATA = "data/admin-details"
export const GET_COUNTRIES = "master/countries"
export const GET_REGIONS = "master/regions"
export const GET_PARTIES = "master/party"
export const RESET_PASSWORD = "reset-password"
export const REFRESH_TOKEN = "refresh"
export const GET_HUBS = "master/hubs"
export const GET_EXPERTISE = "master/expertise"
export const ADD_EXPERTISE = "master/add-expertise"
export const ADD_COUNTRY = "master/add-country"
export const EDIT_EXPERTISE = "master/edit-expertise"
export const DELETE_EXPERTISE = "master/remove-expertise"
export const ADD_REGION= "master/add-region"
export const DELETE_REGION = "master/remove-region"
export const EDIT_REGION = "master/edit-region"
export const DELETE_COUNTRY = "master/remove-country"
export const EDIT_COUNTRY = "master/edit-country"
export const GET_MEMBERS = "data/all-members"
export const GET_MEMBERS_BY_ID = "data/hub-members"
export const ADD_HUB = "master/add-hub"
export const EDIT_HUB = "master/edit-hub"
export const GET_SINGLE_HUB = "data/hub"
export const DELETE_HUB = "master/remove-hub"
export const ADD_MINI_HUB = "master/add-minihub"
export const GET_SINGLE_MINI_HUB = "data/minihub"
export const DELETE_MINI_HUB = "master/remove-minihub"
export const ADD_SECTION = "master/add-section"
export const GET_SINGLE_SECTION = "data/section"
export const ADD_AFFILIATION ="master/add-party"
export const DELETE_AFFLIATION = "master/remove-party"
export const GET_SINGLE_PARTY =(id)=> `data/single-party/${id}`
export const EDIT_AFFILIATION = 'master/edit-party'
export const ADD_USER = "add-user"
export const GET_FEED ="feeds/all"
export const ADD_FEED_POST = "feeds/add-post"
export const ADD_FEED_POLL = "feeds/add-poll"
export const HOME_DATA = "/home"

export const GET_MANAGE_FLAG = "flagged/manage"
export const KEEP_POST = "flagged/keep"
export const DELETE_MANAGE_FLAG = "flagged/remove"
export const GET_REMOVED_FLAG = "flagged/removed"
export const FLAG_REINSTATE = "flagged/reinstate"
export const EVENT_DATA = "event"
export const ADD_EVENT = "event/add-event"
export const GET_MINIHUBS = "master/minihubs"
export const EDIT_ADMIN = "edit-admin";
export const CHANGEPASSWORD_API = "changePassword";
export const OTPVERIFICATION_API = "otpVerification";
export const OTPVERIFICATION_API_LOGIN = "verify-second";
export const FORGOTPASSWORD_API = "forgotPassword";
export const ADMIN_DETAILS = "data/profile";
export const GET_REQUESTDATA = "request";
export const APPROVE_PEOPLE = "request/approvePeople";
export const APPROVE_Calendar = "request/approveEvent";
export const DECLINE_PEOPLE = "request/decline";
export const DIRECTORY_ACCESS = "user/access";
export const EDIT_MINIHUB = "master/edit-minihub";
export const EDIT_SECTION = "master/edit-section";
export const EDIT_USER = "edit-user";
export const FEEDS_SINGLEPOST = "feeds/singlePost";
export const GET_GENERAL_NOTIFICATION = "notification/general-notification"
export const ADD_GENERAL_NOTIFICATION = "notification/general"
export const GET_ORGANIZATION = "data/organization";
export const GET_THINKTANK = "data/thinktank";
export const ADD_RESOURCES = "master/section/post"
export const EDIT_RESOURCES = "master/section/edit-post"
export const DELETE_RESOURCES = "master/section/remove-post"
export const INVITE_NOTIFICATION = "invite"
export const INVITE_ALL_NOTIFICATION = "inviteAll"
export const EDIT_EVENT = "event/edit-event"
export const REMOVE_EVENT = "event/remove-event"
export const FEED_GET_POLL = "feeds/getPoll"
export const FEED_GET_POST = "feeds/getPost"
export const NON_HUB_MEMBERS = "data/non-hub-members"
export const ADD_NON_HUB_MEMBERS = "master/add-members"
export const MAINTENANCE_MODE = "maintenance"
export const DEACTIVATE_USER = "user/deactivate"
export const REMOVE_MEMBERS_HUB = "master/remove-members"
export const PARTY_DISABLED = "master/disable-party"





