import PostWrapper from "./PostWrapper"
import react, { useEffect, useState } from "react"
import { fetchManageFlag } from "../../Utils/services"
import { toast } from "react-toastify"


const ManageTab = () => {
    const [flagData, setFlagData] = useState([])

    useEffect(() => {
        getManageFlag()
    },[])
    

    const getManageFlag = async () => {
        try {
            const res = await fetchManageFlag()
            //console.log(res);
            setFlagData(res.data)
            // toast.success(res.message)
        } catch (error) {
            toast.success(error.message)
        }
    }


    return (
        <>
            {
                flagData.length > 0 && (
                    <div className="custom-table bg-white" id="manage-tab">
                        <ul className="head sticky top-0">
                            <li> Post </li>
                            <li> Reported By </li>
                            <li> Reported On </li>
                            <li> Issue </li>
                            <li> Comments </li>
                        </ul>

                        <div className="body">
                            {
                                flagData.map((item, indx) => (
                                    <PostWrapper
                                        data={item}
                                        key={indx}
                                        reRenderFlags={getManageFlag}
                                    />
                                ))
                            }

                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ManageTab