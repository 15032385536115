const FilePost = ({ avatarClass }) => {
    return (
        <div className="post-detail relative pt-4">
            <div className="header">
                <img alt="" src="/assets/avatar.png" className={avatarClass} />
                <span className="pt-1 text-sm primary-color font-semibold tracking-wider"> Dr Hakima el Haité </span>
            </div>

            <div className="p-4 pt-10 bg-1">
                <h3 className="text-sm font-semibold primary-color tracking-wider mb-1"> Article Heading/ Post Caption </h3>
                <p className="text-xs text-paraText">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et...
                </p>

                <ul className="mt-5">
                    <li className="p-2 bg-white text-xs mb-2 flex items-center">
                        <span className="mr-3">
                            <img src="" />
                        </span>

                        <span className="mr-3">
                            fileName
                        </span>

                        <span className="mr-3">
                            <img src="" />
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FilePost;