import { useEffect, useState, useContext } from "react";
import PostDetail from "../Common/PostDetail";
import SimpleListing from "../Common/SimpleListing";
import GridListing from "../Common/GridListing";
import demoData from "./demoData";
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { getHomeApiData } from "../../Utils/services";
import { toast } from "react-toastify";
import ReactChartPage from "./ReactChartPage";
import { ModalContext } from '../../Context'
import PostDetails from "../ModalTemplate/PostDetails"
import { filterPostType } from '../../functions'
import classNames from "classnames";


const _chartData = {
    labels: [],
    datasets: [
      {
        label: "Updates",
        data: [],
        fill: true,
        backgroundColor: "rgba(217, 238, 247, 0.8)",
        borderColor: "rgba(20, 114, 249, 0.8)",
      },
    ]
};

const downward = '/assets/icons/downRedArrow.svg'
const upward = '/assets/icons/upward-arrow.png'



const Home = () => {
    const [popularSearchData, setpopularSearchData] = useState([]);
    const [popularMembersData, setPopularMembersData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [hubData, setHubData] = useState([]);
    const [allHomeData, setAllHomeData] = useState({});
    const [chartData, setChartData] = useState(_chartData)
    const [recentPost, setRecentPost] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [postData, setPostData] = useState([]);

    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    useEffect(() => {
        getHomeAllData();
    }, []);


    const getHomeAllData = async () => {
        try {
            const result = await getHomeApiData();
            // const home_data = result.res;
            const home_data = result.res;
            
            setAllHomeData(home_data)

            if (home_data.popularSearch.length > 0) {
                setpopularSearchData(home_data.popularSearch)
            }

            if (home_data.popularMembers.length > 0) {
                setPopularMembersData(home_data.popularMembers)
            }

            if (home_data.party.length > 0) {
                setPartyData(home_data.party)
            }

            if (home_data.hub.length > 0) {
                setHubData(home_data.hub)
            }

            if (home_data.recentPosts.length > 0) {
                setRecentPost(home_data.recentPosts)
                const _allData = home_data.recentPosts;
                const _pollData = _allData.filter(item =>{
                    if(item.isPoll == true){
                        return item
                    }
                })
                const _postData = _allData.filter(item =>{
                    if(item.isPoll == false){
                        return item
                    }
                })
                setPollData(_pollData)
                setPostData(_postData)
            }
            const labels = home_data.updates.graph.map(item => item._id)
            const data = home_data.updates.graph.map(item => item.count)
            _chartData.labels = labels
            _chartData.datasets[0].data = data
            //console.log(_chartData, '_chardata', home_data);
            setChartData(_chartData)
        }
        catch (error) {
            //console.log(error)
            // toast.error(error.message);
        }
    }

    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails
            data={data}
            isModalView={true}
            isReportBtn={true}
        />
        handleModalData(POST_MODAL)
    }

    //console.log(allHomeData, "allHomeData")

    return (
        <>
            {
                Object.keys(allHomeData).length > 0 && (

                    <div className="px-10 grid grid-cols-12 gap-x-20">

                        <div className="col-span-9">

                            <div className="grid grid-cols-12 gap-x-10">
                                <div className="col-span-3 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <h1 className="text-4xl primary-color mb-1">
                                        { allHomeData.engagement.count }
                                    </h1>

                                    <p className="text-muteText text-xs font-semibold mb-2"> ENGAGEMENT </p>
                                    
                                    {
                                        allHomeData.engagement.percent && 
                                        <div
                                            className={classNames({
                                                "flex mb-2": true,
                                                "items-center": true,
                                                "upward": (allHomeData.engagement.percent > 0),
                                                "downward": !(allHomeData.engagement.percent > 0)
                                            })}
                                        >
                                            <img
                                                src={allHomeData.engagement.percent > 0 ? upward : downward}
                                                className="mr-2"
                                            />

                                            { `${allHomeData.engagement.percent}%` }
                                        </div>
                                    }

                                </div>

                                <div className="col-span-6 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <ReactChartPage data={chartData} />
                                </div>
                                
                                <div className="col-span-3 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <h1 className="text-4xl primary-color mb-1">
                                        { allHomeData.views.count }
                                    </h1>

                                    <p className="text-muteText text-xs font-semibold mb-2"> VIEWS </p>

                                    {
                                        allHomeData.views.percent != null && 
                                        <div
                                            className={classNames({
                                                "flex mb-2": true,
                                                "items-center": true,
                                                "upward": (allHomeData.views.percent > 0),
                                                "downward": !(allHomeData.views.percent > 0)
                                            })}
                                        >
                                            <img
                                                src={allHomeData.views.percent > 0 ? upward : downward}
                                                className="mr-2"
                                            />

                                            { `${allHomeData.views.percent}%` }
                                        </div>
                                    }
                                </div>
                            </div>



                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> TOTAL USERS </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-4xl primary-color mr-2">
                                            { allHomeData.totalUsers.count }
                                        </h1>
                                        <span className="new-added-users">
                                            { `+${allHomeData.totalUsers.change} users` }
                                        </span>
                                    </div>
                                </div>

                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> ACTIVE USERS </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData.activeUsers.change > 0),
                                            "downward": !(allHomeData.activeUsers.change > 0)
                                        })}
                                    >
                                        <h1 className="text-4xl primary-color mr-2">
                                            { allHomeData.activeUsers.count }
                                        </h1>

                                        <img src={allHomeData.activeUsers.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData.activeUsers.change} %` }
                                    </div>
                                </div>

                                <div className="text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> INACTIVE USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData.inactiveUsers.change > 0),
                                            "downward": !(allHomeData.inactiveUsers.change > 0)
                                        })}
                                    >
                                        <h1 className="text-4xl primary-color mr-2">
                                            { allHomeData.inactiveUsers.count }
                                        </h1>
                                        <img src={allHomeData.inactiveUsers.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData.inactiveUsers.change} %` }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mt-10">
                                <h1 className="text-xl tracking-wider primary-color font-semibold mb-2">Trending Feeds</h1>

                                <div className="grid grid-cols-2 py-16 bg-white">
                                    <div className="px-16 border-r-2">
                                        { filterPostType(allHomeData.trendingPosts[0], showPostDetail) }
                                    </div>

                                    <div className="px-16">
                                        { filterPostType(allHomeData.trendingPosts[1], showPostDetail) }
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-x-10 mt-10">
                                {popularSearchData && <SimpleListing
                                    title="Popular Search Items"
                                    list={popularSearchData}
                                />}

                                {popularMembersData && <GridListing
                                    title="Popular Members"
                                    list={popularMembersData}
                                />}
                            </div>

                            <div className="grid grid-cols-2 gap-x-10 mt-10">
                                <GridListing
                                    title="Popular Parties"
                                    list={partyData}
                                />

                                <GridListing
                                    title="Popular Hubs"
                                    list={hubData}
                                />
                            </div>

                        </div>
                        <div className="col-span-3 ">
                            <div className="recent-posts sticky-sidebar sticky top-0">
                                <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> Recent Feeds </h1>
                                    
                                {
                                    allHomeData.recentPosts && allHomeData.recentPosts.map((item , index) => (
                                        <div className="mb-20" key={index}>
                                            { filterPostType(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                
                            </div>

                        </div>

                    </div>
                )
                
            }
        </>
    )
}

export default Home;