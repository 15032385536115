import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import EditEventForm from '../ModalTemplate/EditEventForm';
import EventEditDeletePopUp from '../ModalTemplate/EventEditDeletePopUp';
import ReactCalendarPage from './ReactCalendarPage'

const LiEvent = ({ liData, getEventApiData }) => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [showEvent, setShowEvent] = useState([]);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    const formateTime = (time) => {
        const _Time = new Date(time);
        const hours = _Time.getHours();
        const minutes = _Time.getMinutes();
        const FullTime = `${hours}:${minutes}`
        return FullTime
    }

    const getAllApiDataOnLoad = () => {
        setShowEvent([])
        let array = [];

        liData && liData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(currentDate)
            if (_d1 === _d2) {
                array.push(item)
            }
            else {
                //console.log(false);
            }
        })
        setShowEvent(array)
    }


    useEffect(() => {
        getAllApiDataOnLoad();
    }, [liData])


    const dateHandler = (date) => {
        setCurrentDate(date)
        setShowEvent([])
        let array = [];
        liData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(date)
            if (_d1 === _d2) {
                array.push(item)
            }
            else {
                //console.log(false);
                // setShowEvent([])
            }
            setShowEvent(array)
        })
    }

    const handleActiveStartDate = (value) => {
        const _yearMonth = value.activeStartDate;
        const year = _yearMonth.getFullYear();
        const month = _yearMonth.getMonth() + 1;
        setYear(year)
        setMonth(month)
    }
    useEffect(() => {
        getEventApiData(year, month);
    }, [year, month]);

    const handleEditEventShow = (data, id) => {
        const edit_Form_Modal =
            <EventEditDeletePopUp
                title="Edit LI Event"
                buttonText="Save"
                getEventApiData={getEventApiData}
                eventDetails={data}
                eventId={id}
            />
        handleModalData(edit_Form_Modal, 'sm')
    }


    return (
        <div className="grid grid-cols-4">
            <div className="col-span-3">
                <ReactCalendarPage showEvent={showEvent} value={currentDate} onChange={dateHandler} activeStartDateChange={handleActiveStartDate} />
                <div className="my-8 flex items-center">
                    <div className="flex items-center mr-4">
                        <span className="w-4 h-4 rounded-full bg-sidenav" />
                        <span className="text-paraText ml-2">LI Calendar</span>
                    </div>

                    <div className="flex items-center mx-4">
                        <span className="w-4 h-4 rounded-full bg-yellow-400" />
                        <span className="text-paraText ml-2">Mini Hub Calendar</span>
                    </div>

                    <div className="flex items-center mx-4">
                        <span className="w-4 h-4 rounded-full bg-muteText" />
                        <span className="text-paraText ml-2">User Deadlines</span>
                    </div>
                </div>
            </div>
            <div className="details_main">
                <div className="sticky top-0 sticky-sidebar">
                    <h2 className="text-sidenav text-2xl pb-2 pl-4">Details</h2>
                    {/* {
                        DetailsData.map(data => 
                            (
                                <div className={`p-5 mb-5 ${data.bgColor}`} key={data.id}>
                                    <h3 className={`text-lg ${data.textColorHeading}`}> { data.Heading } </h3>
                                    <p className={`text-sm ${data.textColorPara}`}> { data.admin } </p>
                                    <p className={`text-sm ${data.textColorPara}`}> { data.time } </p>
                                    <p className={`text_tosmall_10 ${data.textColorPara}`}> { data.otherLink } </p>
                                </div>
                            )
                        ) 
                    } */}
                    {
                        showEvent.length > 0 ? (
                            showEvent.map(item => (
                                <div className={`p-5 mb-5 cursor-pointer bg-sidenav`} key={item._id} onClick={() => handleEditEventShow(item, item._id)} >
                                    <h3 className={`text-xl text-white`}> {item.name} </h3>
                                    <h3 className={`text-white`}> {item.description} </h3>
                                    <p className={`text-sm text-white py-1`}> {`${formatDate(item.start)} to ${formatDate(item.end)}`} </p>
                                    <p className={`text-sm text-white`}> {`${formateTime(item.startTime)} to ${formateTime(item.endTime)}`} </p>
                                    <p
                                        className={`text-sm py-1 text-white`}
                                    >
                                        {item.timezone}
                                    </p>
                                    <p className={`text_tosmall_10 text-white`}>  {item.links.map((data , idx) => {
                                        return (
                                            <div key={idx}>
                                                <p>{data}</p>
                                            </div>
                                        )
                                    })} </p>
                                </div>

                            ))

                        )

                            :
                            (<div className="ml-5">
                                <p>No Events</p>
                            </div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default LiEvent
