import { useEffect, useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ModalContext } from '../../Context'
import { toast } from "react-toastify";
import Loader from "../Common/Loader"
import { BASE_URL_IMG, editMiniHubApi } from '../../Utils/services';
import { Checkbox, FormControlLabel } from '@material-ui/core';



const _helperText = {
    badge: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option",
    expertise: "*start typing, the list will show up for multiple selects - Press enter to make an entry and then again start typing for selecting the second option"
}



const EditMinihub = ({
    title,
    btnText,
    getMembers,
    id,
    miniHubDetails,
    fetchMiniHubDetails,
    hubId
}) => {

    const [members, setMembers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [allFieldsData, setAllFieldsData] = useState({
        name: miniHubDetails && miniHubDetails.name,
        description: miniHubDetails && miniHubDetails.description,
        members: [],
        file: miniHubDetails && miniHubDetails.logo,
        abbreviation: miniHubDetails && miniHubDetails.abbreviation,
        url: miniHubDetails && miniHubDetails.url
    })
    const [AllMembers, setAllMembers] = useState(false);
    const context = useContext(ModalContext)
    const { closeModal } = context;


    useEffect(() => {
        _getMembers()
    }, [])


    const _getMembers = async () => {
        if (getMembers) {
            const res = await getMembers(hubId)
            setMembers(res);
        }
    }


    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 200 && img.height === 200) {
                    setAllFieldsData({
                        ...allFieldsData,
                        file: val
                    })
                }
                else {
                    setAllFieldsData({
                        ...allFieldsData,
                        file: {}
                    })
                }
            }
        }
    }



    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'members') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }

    const formSubmit = async (e) => {
        setLoader(true);
        e.preventDefault()
        const formData = new FormData();
        formData.append('name', allFieldsData.name)
        formData.append('description', allFieldsData.description)
        formData.append('media', allFieldsData.file)
        formData.append('abbreviation', allFieldsData.abbreviation)
        formData.append('id', id)
        // const membersData = allFieldsData.members && allFieldsData.members.map((item) => {
        //     return item._id
        // })
        const _members = []
        if (AllMembers === false) {
            allFieldsData.members.map(item => {
                _members.push(item._id)
            })
        }
        else {
            members.map(item => {
                _members.push(item._id)
            })
        }
        formData.append("members", _members)
        try {
            const result = await editMiniHubApi(formData)
            if (result.res.success) {
                toast.success(result.res.message)
                fetchMiniHubDetails();
            }
            setAllFieldsData({
                name: '',
                description: '',
                members: [],
                file: {},
                abbreviation: '',
                // url: ''

            })
            setLoader(false);
            closeModal()
        }
        catch (e) {
            setLoader(false);
            toast.error(e.message);
        }

    }


    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full">
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">

                    <TextField
                        label="Name"
                        name="name"
                        onChange={onInputChange}
                        value={allFieldsData.name || ''}
                    />

                    <div className="mb-4">
                        <div className="relative w-auto overflow-hidden">
                            {
                                typeof allFieldsData.file === "string" &&
                                <img src={`${BASE_URL_IMG}${allFieldsData.file}`} className="mb-1" style={{ maxWidth: "100px" }} />
                            }
                            <TextField
                                id="profilephoto"
                                label={'Add a Profile Photo'}
                                value={allFieldsData.file.name || ''}
                                className="w-full text-xs relative"
                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="profile-photo"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800">Note: Image dimensions should be 200x200px </p>
                    </div>


                    <TextField
                        name="description"
                        label="Enter Description"
                        value={allFieldsData.description || ''}
                        aria-label="minimum height"
                        onChange={onInputChange}
                        minRows={3}
                    />
                    <TextField
                        name="abbreviation"
                        label="Enter Abbreviation"
                        value={allFieldsData.abbreviation || ''}
                        aria-label="minimum height"
                        onChange={onInputChange}
                        minRows={3}
                    />
                    <TextField
                        name="url"
                        label="Add URL"
                        aria-label="minimum height"
                        placeholder='Use HTTPS URLS'
                        onChange={onInputChange}
                        minRows={3}
                    />
                    {
                        AllMembers === false &&
                        <Autocomplete
                            multiple
                            limitTags={2}
                            name="members"
                            options={members}
                            getOptionLabel={(option) => option.name}
                            value={allFieldsData.members}
                            onChange={(e, newVal) => onInputChange(e, newVal, 'members')}
                            renderInput={(params) => <TextField {...params} id="badge" label="Add a Member" helperText={_helperText.badge} />}
                        />
                    }
                    <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={AllMembers} onChange={(e) => setAllMembers(e.target.checked)} defaultChecked color="primary" />} label="Select All Members" labelPlacement="start" />
                    </div>

                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" onClick={formSubmit} disabled={loader ? true : false}>
                        {btnText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditMinihub;