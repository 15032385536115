import UserForm from "./UserForm";
import FeedsForm from "./FeedsForm";
import PostDetails from "./PostDetails";
import RemoveConfirmation from "./RemoveConfirmation"
import modalTextsFile from "./modalTextsFile"
import ResetPassword from "./ResetPassword";

export const USER_FORM = <UserForm title="add new user" buttonText="add user"/>
export const INTERNATIONAL_OFFICER_USER_FORM = <UserForm title="add new international Officer" buttonText="add international Officer"/>
export const FEEDS_FORM = (post, getFeedData) => <FeedsForm getFeedData ={getFeedData} title="add new feed" post={post}/>
export const POST_MODAL = <PostDetails />
export const REMOVE_CONFIRMATION = <RemoveConfirmation title="REMOVE FEED" data={modalTextsFile.postRemoveText}/>
export const RESET_PASSWORD = <ResetPassword />
