import Table from '../../Common/Table';
import { Button, Grid, TextField } from "@material-ui/core";
import { useEffect, useState, useContext, useMemo } from 'react';
import AntSwitch from '../../Common/AntSwitch'
import { ModalContext } from '../../../Context'
import Form from '../../ModalTemplate/AffiliationForm/Form'
import { getPartyData } from '../getRequests';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaintenanceModeConfirmation from '../../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../../ModalTemplate/modalTextsFile';
import { BASE_URL_IMG, partyDisabledApi } from '../../../Utils/services';
import { toast } from 'react-toastify';

const demoData = [
    {
        logo: 'image',
        name: 'Name',
        internationalOfficer: {
            name: 'International Officer'
        },
        headquaters: {
            name: 'Headquaters'
        },
        type: 'Type',
        createdOn: 'createdOn',
        liMember: true
    }
]

const AffiliationFilterAllFileds = [
    // {
    //     label: "All",
    //     value: "All"
    // },
    {
        label: "Party",
        value: "Party"
    },
    {
        label: "Organisation",
        value: "Organisation"
    },
    {
        label: "Think Tank",
        value: "Think Tank"
    }
]


const Affiliation = ({ affiliationData, setAffiliationData }) => {
    const [filterField, setFilterField] = useState([]);
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }


    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value
        setFilterField({
            ...filterField,
            [name]: newInputValue
        })
    }

    const editParty = () => {
        alert('Edit Party')
    }

    const editOranisation = () => {
        alert('Edit Oranisation')
    }

    const editThinkTank = () => {
        alert('Edit ThinkTank')
    }

    const editHandler = (data) => {
        if (data.row.original.category === 1) {
            const EDIT_PARTY = <Form
                setAffiliationData={setAffiliationData}
                id={data.row.original._id}
                title="EDIT PARTY"
                btnText="SAVE"
                type={1}
                perticularData={data.row.original}
                edit
            />
            handleModalData(EDIT_PARTY);
        }
        else if (data.row.original.category === 2) {
            const EDIT_PARTY = <Form
                setAffiliationData={setAffiliationData}
                id={data.row.original._id}
                title="EDIT ORGANISATION"
                btnText="SAVE"
                type={2}
                perticularData={data.row.original}
                edit
            />
            handleModalData(EDIT_PARTY);
        }
        else {
            const EDIT_PARTY = <Form
                setAffiliationData={setAffiliationData}
                id={data.row.original._id}
                title="EDIT THINK TANK"
                btnText="SAVE"
                type={3}
                perticularData={data.row.original}
                edit
            />
            handleModalData(EDIT_PARTY);
        }
    }

    const getPartyAllData = async () => {
        const result = await getPartyData();
        setAffiliationData(result);
        if (filterField && filterField.filter && filterField.filter.value === "Party") {
            const _partyData = result && result.filter((item) => {
                return item.category === 1
            })
            setAffiliationData(_partyData)
        }
        else if (filterField && filterField.filter && filterField.filter.value === "Organisation") {
            const _partyData = result && result.filter((item) => {
                return item.category === 2
            })
            setAffiliationData(_partyData)
        }
        else if (filterField && filterField.filter && filterField.filter.value === "Think Tank") {
            const _partyData = result && result.filter((item) => {
                return item.category === 3
            })
            setAffiliationData(_partyData)
        }
    }

    useEffect(() => {
        getPartyAllData();
    }, [filterField])

    const handlePartyDisabled = async (id) => {
        try {
            const _result = await partyDisabledApi(id);
            toast.success(_result.res.message)
            closeModal();
            getPartyAllData();
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleDeactivate = async (props) => {
        const id = props.row.original._id;
        const USER_DEACTIVATE = <MaintenanceModeConfirmation
            title="Change Party Status"
            data={modalTextsFile.PartyActivateDeactivate}
            action={() => handlePartyDisabled(id)}
        />
        handleModalData(USER_DEACTIVATE, 'sm')
    }



    const columns = useMemo(
        () => [
            {
                Header: 'Logo',
                accessor: 'logo', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) =>
                    <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className="w-10" />
            },

            {
                Header: 'Name',
                accessor: 'name'
            },

            {
                Header: 'International Officer',
                accessor: 'internationalOfficer',
                Cell: ({ cell: { value } }) => <div>{value.map((item) => item.name + ",")}</div>
            },

            {
                Header: 'Country',
                // Header: 'Headquarter',
                accessor: 'headquarters',
                Cell: ({ cell: { value } }) => <span className="capitalize">{value}</span>
            },

            {
                Header: 'Type',
                accessor: 'category',
                Cell: ({ cell: { value } }) => <p>{value === 1 ? "Party" : value === 2 ? "Organisation" : "Think Tank"}</p>
            },

            {
                Header: 'Created On',
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },

            {
                Header: 'LI Member',
                accessor: 'LI',
                Cell: ({ cell: { value } }) =>
                    <AntSwitch
                        checked={value}
                        name="isDirectory"
                    />
            },
            {
                Header: "Party Status",
                accessor: "",
                Cell: props => <Grid item>
                    <AntSwitch
                        checked={!props.row.original.disabled}
                        onChange={() => handleDeactivate(props)}
                        name="partyStatus"
                    />
                </Grid>
            },
            {
                Header: "Action",
                accessor: "_id",
                Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
            }
        ], [])




    return (
        <>
            <div className='flex justify-end'>
                <Autocomplete
                    name="filter"
                    options={AffiliationFilterAllFileds}
                    getOptionLabel={(option) => option.label}
                    value={filterField.value}
                    onChange={(e, newVal) => onInputChange(e, newVal, 'filter')}
                    renderInput={(params) => <TextField {...params} id="filter" label="Filter" />}
                    className="w-56"
                />
            </div>
            {
                affiliationData && <Table columns={columns} data={affiliationData.sort(dynamicSort("name"))} />
            }
        </>
    )
}

export default Affiliation