import classNames from "classnames"
import { BASE_URL_IMG } from "../../Utils/services"
import avatarImg from "../../Assets/images/DummyDp.png"


const GridListing = ({ title, list }) => {
    return (
        <div>
            <h1 className="text-xl tracking-wider primary-color font-semibold mb-2">
                { title }
            </h1>

            <ul className="p-10 bg-white pb-6">
                {
                    list.map((item, index, array) => (
                        <li key={index} className={classNames({
                            'mb-5 pb-4 text-sm flex items-center': true,
                            'border-b': ((array.length - 1) !== index)
                        })}>
                            <div className="w-12 mr-5">
                                <img src={ item.image ? `${BASE_URL_IMG}${item.image}?${new Date().getTime()}` : item.logo ? `${BASE_URL_IMG}${item.logo}?${new Date().getTime()}` : avatarImg} alt="" className="rounded-icon" />
                            </div>
                            <div className="">
                                <p className="text-sm tracking-wider primary-color font-semibold">
                                    {item.name}
                                </p>

                                <p className="text-xs tracking-wider text-muteText">
                                    {item.subTitle}
                                </p>
                            </div>

                            <div className="text-xs tracking-wider text-muteText ml-auto">
                                { item.btnText}
                            </div>
                        </li>
                    ))
                }
            </ul>
            
        </div>
    )
}

export default GridListing