import React from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loader }) => {
    const override = css`
        position : absolute;
        top : 50%;
        left : 50%;
    `;
    return (
        <>
            {
                loader && <ClipLoader size={100} loading={loader} color="#75ea9c" css={override} />
            }
        </>
    )
}

export default Loader
