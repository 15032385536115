import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useState } from 'react';
import { addFeedPost, getFeed } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"


const FeedsPost = ({ getFeedData }) => {
    const [formData, setFormData] = useState({});
    const [imgFile, setImgFile] = useState('');
    const [VideoFile, setVideoFile] = useState('');
    const [pdfFile, setPdfFile] = useState('');
    const [linkFile, setLinkFile] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("visual")
  const [youtubelink, setYoutubeLink] = useState([]);

    const handleImgFile = (val) => {
        setImgFile(val);
        setMediaType("visual")
        if (val.name !== "") {
            setDisableButton(true)
        }
    }
    const handleVideoFile = (val) => {
        setVideoFile(val);
        setMediaType("video")
        if (val.name !== "") {
            setDisableButton(true)
        }
    }
    const handlePdfFile = (val) => {
        setPdfFile(val);
        setMediaType("file")
        if (val.name !== "") {
            setDisableButton(true)
        }
    }
    const handleLinkFile = (val) => {
        setLinkFile(val);
        setMediaType("link");
        if (val.name !== "") {
            setDisableButton(true)
        }
    }
    const HandleYoutubeLink = (e) => {
        setYoutubeLink(e.target.value);
        setMediaType("link");
        if(e.target.value !== ""){
            setDisableButton(true)
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    //console.log(formData, "Form Data")

    const handlePostSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        const postFormData = new FormData();
        postFormData.append("title", formData.title ? formData.title : "");
        postFormData.append("location", formData.location ? formData.location : "");
        postFormData.append("caption", formData.caption ? formData.caption : "");
        postFormData.append("media", imgFile && imgFile || VideoFile && VideoFile || pdfFile && pdfFile || youtubelink && youtubelink);
        postFormData.append("mediaType", mediaType)

        try {
            const result = await addFeedPost(postFormData);
            if (result.res.success) {
                toast.success(result.res.message);
                closeModal();
                getFeedData();
                setLoader(false);
                //console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            //console.log(error);
            toast.error(error.message);
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div>
                        <TextareaAutosize
                            name="title"
                            value={formData.title || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder="Post Title"
                            minRows={3}
                        />
                        <TextField
                            name="location"
                            value={formData.location || ''}
                            onChange={(e) => onChangeHandler(e)}
                            id="location"
                            label="Location"
                            className="w-full"
                        />
                    </div>
                    <TextareaAutosize
                        name="caption"
                        value={formData.caption || ''}
                        onChange={(e) => onChangeHandler(e)}
                        id="postname"
                        aria-label="Empty"
                        placeholder="Share you Views"
                        minRows={5}
                    />
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item md={1}>
                            <span className="text-paraText">Add Media</span>
                        </Grid>
                        <Grid item md={2}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a Profile Photo"
                                    value={imgFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photos
                            </div>
                        </Grid>
                        <Grid item md={1} className="flex items-center justify-center">
                            <span className="text-muteText">or</span>
                        </Grid>
                        <Grid item md={2}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a Video"
                                    value={VideoFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="video/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="video-file"
                                    onChange={(e) => handleVideoFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/video.svg" alt="" />
                                Video
                            </div>
                        </Grid>
                        <Grid item md={1} className="flex items-center justify-center">
                            <span className="text-muteText">or</span>
                        </Grid>
                        <Grid item md={2}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a PDF File"
                                    value={pdfFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="pdf-file"
                                    onChange={(e) => handlePdfFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/photos.svg" alt="" />
                                Files
                            </div>
                        </Grid>
                        <Grid item md={1} className="flex items-center justify-center">
                            <span className="text-muteText">or</span>
                        </Grid>
                        <Grid item md={2}>
                            <div className="relative w-auto overflow-hidden">
                                {/* <TextField
                                    id="youtubelink"
                                    label="Add a Link"
                                    name="youtubelink"
                                    value={linkFile.name || ""}
                                    className="w-full text-xs"
                                /> */}
                                <TextField
                                    id="youtubelink"
                                    type={"url"}
                                    name="youtubelink"
                                    label="Add a Link"
                                    value={youtubelink}
                                    onChange={HandleYoutubeLink}
                                    className="w-full text-xs"
                                />
                                {/* <input
                                    disabled={disableButton}
                                    type="file"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="link-file"
                                    onChange={(e) => handleLinkFile(e.target.files[0])}
                                /> */}
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/link.svg" alt="" />
                                YouTube/Vimeo Link
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        Add Post
                    </button>
                </div>
            </form>
        </>
    )
}

export default FeedsPost