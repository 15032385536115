import { Button } from "@material-ui/core";
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { ModalContext } from '../../Context'
import { BASE_URL_IMG } from "../../Utils/services";
import { REMOVE_CONFIRMATION } from "../ModalTemplate"


const HubDescription = ({
    data,
    section,
    editHandler,
    removeHandler,
    removeBtn
}) => {

    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const history = useHistory();

    const handleHubPush = () => {
        history.goBack();
    }

    return (
        <div className="w-full">
            <div className="pb-3 ">
                <button onClick={handleHubPush} className="text-sm text-sidenav border-b border-solid border-sidenav cursor-pointer">Back</button>
            </div>
            <div className="w-full">
                <img src={`${BASE_URL_IMG}${data.logo}?${new Date().getTime()}` || ''} className="w-32 mb-10" alt="" />
                <h1 className="text-2xl primary-color font-semibold tracking-widest mb-5 capitalize">
                    {data.name}
                </h1>
                <Button
                    className="secondary-btn"
                    onClick={editHandler}
                >
                    <span className="minus" />
                    Edit
                </Button>
            </div>

            <div className="w-full text-sm mt-10">
                <p className="primary-color font-semibold text-l mb-3"> Description </p>
                <p> {data.description || ''} </p>
                <div className="mt-3">
                    <p className="primary-color font-semibold text-l mb-3">URL</p>
                    <p> {data.url || ''} </p>
                </div>
                <div className="mt-3">
                    <p className="primary-color font-semibold text-l mb-3">Abbreviation</p>
                    <p> {data.abbreviation || ''} </p>
                </div>
                <ul className="my-8">
                    {
                        section && (
                            <li className="flex items-center mb-5">
                                <label className="primary-color font-semibold text-l w-2/5">
                                    Manager
                                </label>
                                <span className="flex items-center">
                                    <img src={`${BASE_URL_IMG}${data.manager.image}?${new Date().getTime()}`} alt="" className="rounded-icon mr-2" />
                                    {data.manager.name}
                                </span>
                            </li>
                        )
                    }
                    <li className="flex items-center mb-5">
                        <label className="primary-color font-semibold text-l w-2/5">
                            Community Members
                        </label>
                        <span> {data.communityMembers || ''} </span>
                    </li>
                    <li className="flex items-center mb-5">
                        <label className="primary-color font-semibold text-l w-2/5">
                            Number of Feeds
                        </label>
                        <span> {data.feedCount || ''} </span>
                    </li>
                    <li className="flex items-center mb-5">
                        <label className="primary-color font-semibold text-l w-2/5">
                            Number of Attachments
                        </label>
                        <span>37</span>
                    </li>
                    <li className="flex items-center mb-5">
                        <label className="primary-color font-semibold text-l w-2/5">
                            Created on
                        </label>
                        <span> {data.formateDate || ''} </span>
                    </li>
                    <li className="flex items-center mb-5">
                        <label className="primary-color font-semibold text-l w-2/5">
                            Created by
                        </label>
                        <span className="flex items-center">
                            <img src={`${BASE_URL_IMG}${data.createdBy.image}?${new Date().getTime()}` || ''} alt="" className="w-8" />
                            {data.createdBy.name || ' '}
                        </span>
                    </li>
                </ul>

                <Button
                    variant="outlined"
                    color="secondary"
                    className="approve_decline_padding"
                    onClick={removeHandler}
                >
                    <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                    <span className="pl-1">{removeBtn}</span>
                </Button>

            </div>
        </div>
    )
}

export default HubDescription;