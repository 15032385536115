const modalTextsFile = {
    postRemoveText:"<p>Are you sure, you want to delete this Feed?</p> <p> Feed once removed <b>can</b> be accessed from the 'Removed' tab under Flagged.</p>",
    expertiseRemoveText:"<p>Are you sure, you want to remove this expertise? <br/>Expertise once deleted <b>cannot</b> be restored.</p>",
    postRemoveText: "<p>Are you sure, you want to delete this Feed?</p> <p> Feed once removed <b>can</b> be accessed from the 'Removed' tab under Flagged.</p>",
    hubsRemoveText: "<p>Are you sure, you want to remove this hub?</p> <p>Hub once deleted <b>cannot</b> be restored.</p>",
    miniHubRemoveText: "<p>Are you sure, you want to remove this mini hub?</p> <p>Mini Hub once deleted <b>cannot</b> be restored.</p>",
    sectionRemoveText: "<p>Are you sure, you want to remove this section?</p> <p>Section once deleted <b>cannot</b> be restored.</p>",
    attachmentRemoveText: "<p>Are you sure, you want to remove this attachment?</p> <p>Attachment once deleted <b>cannot</b> be restored.</p>",
    countryRemoveText: "<p>Are you sure, you want to remove this Country?</p> <p>Country once deleted <b>cannot</b> be restored.</p>",
    regionRemoveText: "<p>Are you sure, you want to remove this region?</p> <p>Region once deleted <b>cannot</b> be restored.</p>",
    affiliationRemoveText: "<p>Are you sure, you want to remove this affiliation?</p> <p>Affiliation once deleted <b>cannot</b> be restored.</p>",
    flagRemoveText: "<p>Are you sure, you want to remove this Feed?</p> <p>Feed once removed <b>can</b> be accessed from the 'Removed' tab under Flagged.</p>",
    EventRemoveText: "<p>Are you sure, you want to remove this Event?</p> <p>Event once removed <b>cannot</b> be restored.</p>",
    MaintenanceMode: "<p>Are you sure, you want to change the maintenance mode.</p>",
    userActivateDeactivate: "<p>Are you sure, you want to change the status of user.</p>",
    PartyActivateDeactivate: "<p>Are you sure, you want to change the status of Party.</p>"
}

export default modalTextsFile