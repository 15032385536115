import PostDetail from "./Components/Common/PostDetail"
import YoutubeVideoPost from "./Components/Common/YoutubeVideoPost"
import FilePost from "./Components/Common/FilePost"
import ArticlePost from "./Components/Common/ArticlePost"
import PostPolling from "./Components/Common/PostPolling"
import VideoPlayer from "./Components/Common/VideoPlayer"




export const filterPostType = (data, onClickHandler, isModalView = false, isReportBtn = false) => {
    const isMedia = data &&  (data.media != null) && (data.media.length > 0) 
    // //console.log(data, isModalView, isReportBtn);
    
    if (data && data.isPoll) {
        return renderPost('poll', data, onClickHandler, isModalView, isReportBtn)

    } else if (isMedia) {
        const type = data.media[0].mediaType
        return renderPost(type, data, onClickHandler, isModalView, isReportBtn)
    }
    else {
        return renderPost('articlePost', data, onClickHandler, isModalView, isReportBtn)
    }
}


export const renderPost = (type, data, onClickHandler, isModalView, isReportBtn) => {
    switch (type) {
        case 'visual':
            return (
                <PostDetail
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'link':
            return (
                <YoutubeVideoPost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )
        case 'video':
            return (
                <VideoPlayer
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'file':
            return (
                <FilePost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'articlePost':
            return (
                <ArticlePost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'poll':
            return (
                <PostPolling
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )
    }

}