import Auth from "./Auth"
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "./Layout"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Utils/auth-context";
import { useContext, useEffect } from "react";
import ForgotPassword from "./Auth/ForgotPassword";
import ChangePassword from "./Auth/ChangePassword";
import OtpVerification from "./Auth/OtpVerification";
import jwtDecode from "jwt-decode"
import { useHistory } from "react-router-dom";
import LoginOtpVerification from "./Auth/LoginOtpVerification";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const App = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const initialToken = localStorage.getItem("token");
  const location = useLocation() ;
  useEffect(()=>{
    if(!!initialToken){
      // //console.log(initialToken , !!initialToken)
      history.push(location.pathname)
    }
  },[])

  // for jwt expires
  // if (authCtx.token) {
  //   const { exp } = jwtDecode(authCtx.token)
  //   const expirationTime = (exp * 1000) - 60000
  //   if (Date.now() >= expirationTime) {
  //     // localStorage.clear();
  //     history.push("/login");
  //   }
  // }
  // for jwt expires

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <Switch>
        <Route path="/login" component={Auth} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/otp" component={OtpVerification} />
        <Route path="/loginOtp" component={LoginOtpVerification} />
        {
          authCtx.isLoggedIn ?
            (<Route path="/" component={Layout} />) :
            (<Route path="/login" component={Auth} />)
        }
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </>
  );
}

export default App;
