import { useContext, useMemo } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import FeedsTable from './FeedsTable';
import flag from '../../Assets/images/flag.svg'
import PostDetails from '../ModalTemplate/PostDetails';
import { ModalContext } from '../../Context';
import { useState } from 'react';
import { useEffect } from 'react';
import { BASE_URL_IMG } from '../../Utils/services';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { deleteManageFlag } from '../../Utils/services';
import { toast } from 'react-toastify';


const PollFeed = ({ data, getPollData, totalCount }) => {
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [totalPageToShow, setTotalPageToShow] = useState(1);

    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }

    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const handlePageChange = (event, value) => {
        setPage(value);
    };


    useEffect(() => {
        // setTotalPageToShow(Math.ceil((result.res.posts.length) / postsPerPage))
        // getFeedData({
        //     page: page,
        //     size: postsPerPage
        // });
        if (totalCount && totalCount > 0) {
            setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
        }
        getPollData({
            page: page,
            size: postsPerPage
        });
    }, [page, totalCount]);


    const handleModal = (data) => {
        const POST_DETAILS = <PostDetails data={data} />
        handleModalData(POST_DETAILS)

    }

    const reportHandler = () => {
        //console.log('reportHandler');
    }

    const showPostDetail = item => {
        const POST_MODAL = <PostDetails
            data={item}
            reportHandler={reportHandler}
            isReportBtn={true}
            isModalView={true}
        />
        handleModalData(POST_MODAL)
    }

    const removeFlag = async (id) => {
        try {
            const body = { id: id }
            const res = await deleteManageFlag(body)
            closeModal()
            getPollData({
                page: page,
                size: postsPerPage
            });
       
            toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const removeHandler = (data) => {
        const REMOVE_CONFIRMATION = <RemoveConfirmation
            title="Remove Feed"
            data={modalTextsFile.flagRemoveText}
            action={removeFlag}
            id={data._id}
        />
        handleModalData(REMOVE_CONFIRMATION, 'sm')
    }



    const FeedsColumns = useMemo(
        () => [
            {
                Header: 'Flag',
                accessor: data => {
                    return (
                        <>
                            {data.flag.isFlagged ? <img onClick={()=>removeHandler(data)}  src={flag} alt="" />: ""}
                        </>
                    )
                },
            },
            {
                Header: 'Name',
                accessor: data => {
                    return (
                        <div key={data.postedBy.id} className="flex items-center">
                            <img src={`${BASE_URL_IMG}${data.postedBy.image}?${new Date().getTime()}`} alt="author" className="w-10 h-10 object-cover rounded-full" />
                            <span className="ml-4">{data.postedBy.name}</span>
                        </div>
                    )
                },
            },
            {
                Header: 'Post Photo',
                accessor: data => {
                    return (
                        <>
                            {
                                data.media[0] ? <img src={`${BASE_URL_IMG}${data.media[0].link}`} alt="post photo" className="w-28" /> : "-"
                            }
                        </>
                    )
                }
            },
            {
                Header: 'Posted In',
                accessor: data => data.postedIn ? data.postedIn : '-',
            },
            {
                Header: 'Posted On',
                accessor: data => data.date ? formatDate(data.date) : '-',
            },
            {
                Header: 'Number of Likes',
                accessor: 'likesCount',
            },
            {
                Header: 'Number of Comments',
                accessor: 'commentsCount',
            },
            {
                Header: 'Number of Views',
                accessor: (viewData => {
                    return viewData.views ? viewData.views : "-"
                }),
            },
            {
                Header: 'Action',
                accessor: (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => showPostDetail(data)}
                        >
                            <span className="MuiButton-label">View</span>
                        </button>
                    )
                },
            },
        ], [])

    return (
        <>
            <div className="flex justify-between items-center">
                <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
            </div>
            {data && <FeedsTable columns={FeedsColumns} data={data} />}

        </>
    )
}

export default PollFeed;