import { useState } from "react";
import Header from "./Header";
import MainWrapper from "./MainWrapper"
import SideNav from "./SideNav"
import CommonModal from "../CommonModal";
import { ModalContext } from "../../Context"



const Layout = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState('');
    const [modalSize, setModalSize] = useState('');
    const [data, setData] = useState({});


    const handleModalData = (data, size = 'xl') => {
        setModalData(data);
        setModalSize(size)
        setIsModalOpen(true);
    }


    const closeModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <ModalContext.Provider
                value={{
                    handleModalData,
                    closeModal,
                    setData,
                    data,
                    isModalOpen,
                    modalData
                }}
            >
                <div className="layout">
                    <div className="side-nav">
                        <SideNav
                        // handleModalData={handleModalData}
                        // isModalOpen={isModalOpen}
                        // modalData={modalData}
                        />
                    </div>

                    <div className="page-container">
                        <Header
                        // handleModalData={handleModalData}
                        // isModalOpen={isModalOpen}
                        // modalData={modalData}
                        />

                        <div className="body-container">
                            <MainWrapper
                            // handleModalData={handleModalData}
                            // isModalOpen={isModalOpen}
                            // modalData={modalData}
                            />
                        </div>
                    </div>
                </div>

                <CommonModal
                    handleModalData={handleModalData}
                    isModalOpen={isModalOpen}
                    modalData={modalData}
                    modalSize={modalSize}
                    closeModal={closeModal}
                />
            </ModalContext.Provider>
        </>
    )
}

export default Layout;