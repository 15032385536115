import React, { useState } from "react";
import { TabPanel } from "../Common/TabPanel";
import People from "./People";
import { USER_FORM, INTERNATIONAL_OFFICER_USER_FORM } from '../ModalTemplate';
import InnerHeader from "../Common/InnerHeader";
import { useEffect } from "react";
import { getRequestDataApi } from "../../Utils/services";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import CalendarRequest from "./CalendarRequest";



const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}


const Request = ({ handleModalData }) => {
  const [tabsValue, setTabsValue] = useState(0);
  const [peopleData, setPeopleData] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getRequestAllData = async () => {
    setLoader(true)
    try {
      const result = await getRequestDataApi();
      //console.log(result)
      if (result.res.success) {
        setLoader(false)
        // toast.success(result.res.message);
        setPeopleData(result.res.data.people)
        setCalendarData(result.res.data.calender)
      }
    } catch (error) {
      setLoader(false)
      //console.log(error);
      toast.error(error)
    }
  }

  useEffect(() => {
    getRequestAllData();
  }, []);

  const tabsData = [
    {
      label: "People",
      data: <>
        <Loader loader={loader} />
        <People peopleData ={peopleData} getRequestAllData ={getRequestAllData} />
      </>
    },
    {
      label: "Calendar",
      data: <>
        <Loader loader={loader} />
        <CalendarRequest calendarData ={calendarData} getRequestAllData ={getRequestAllData} />
      </>
    },

    // {
    //   label: "Hubs & Mini Hub",
    //   data: <People />
    // },
    // {
    //   label: "Calendar",
    //   data: <People />
    // }
  ]

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(USER_FORM);
        break;

      case 1:
        handleModalData(INTERNATIONAL_OFFICER_USER_FORM);
        break;

      case 2:
        handleModalData(INTERNATIONAL_OFFICER_USER_FORM);
        break;

      default:
        handleModalData(USER_FORM);
        break;
    }
  }


  return (
    <div>
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        requestPage
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            key={index}
            className="px-10"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
};

export default Request;
