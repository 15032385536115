import post from "../../Assets/images/post1.png"
import avatar from "../../Assets/images/avatar.png"
import classNames from "classnames"
import ReactHlsPlayer from 'react-hls-player';
import { useState } from "react";
import Slider from "react-slick";
import { BASE_URL_IMG } from "../../Utils/services";



const settings = {
    speed: 800,
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: false
    // responsive: [
    //     {
    //     breakpoint: 1480,
    //     settings: {
    //         slidesToShow: 4,
    //         slidesToScroll: 4,
    //     },
    //     },
    // ],
};



const PostDetail = ({
    avatarClass,
    data,
    onClickHandler,
    isModalView,
    isReportBtn
}) => {

    
    const checkLink = url => {
        const linkType = url.split('.').slice(-1)[0]
        if (linkType === 'm3u8') {
            return (
                <ReactHlsPlayer
                    src={url}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                    hlsConfig={{
                        maxLoadingDelay: 4,
                        minAutoBitrate: 0,
                        lowLatencyMode: true,
                    }}
                />
            )
        }
        else {
            return <img src={`${BASE_URL_IMG}${url}?${new Date().getTime()}`} className="w-full" />
        }
    }

    

    return (
        <>
            {
                data && (
                    <div className={classNames({
                        'post-detail': true,
                        'relative': true,
                        'modal-view': isModalView,
                        'without-text': (data.media.length === 0 && !data.isPoll)
                    })}>
                        <>
                            <div className="header">
                                <img alt="" src={`${BASE_URL_IMG}${data.postedBy.image}?${new Date().getTime()}`} className={avatarClass} />
                                <span className="pt-1 text-sm primary-color font-semibold tracking-wider">
                                    { data.postedBy.name || '' }
                                </span>
                            </div>


                            <div className="content">
                                <Slider {...settings}>
                                    {
                                        data.media.map((item , idx) => (
                                            <div key={idx} className="w-full">
                                                { checkLink(item.link) }
                                            </div>
                                        ))
                                    }
                                </Slider>

                                {
                                    isReportBtn && (
                                        <div className="flex">
                                            <button
                                                className="button-1"
                                                type="button"
                                                // disabled ={loader ? true : false}
                                                // onClick={edit ? handleEdit : handleAdd}
                                            >
                                                Report
                                            </button>
                                        </div>
                                    )
                                }

                            </div>


                            <div className="footer bg-white p-2">
                                <h3 className="title text-sm font-semibold tracking-wider mb-1"> { data.title } </h3>
                                <p className="text text-sm">
                                    { data.caption }

                                    {
                                        !isModalView && (
                                            <span className="text-btn2" onClick={() => onClickHandler(data)}>
                                                Read Article
                                            </span>
                                        ) 
                                    }
                                </p>
                            </div>
                        </>
                    </div>
                )
            }
        </>
    )
}

export default PostDetail