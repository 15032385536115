import { Button } from "@material-ui/core";
import FalseIcon from "../../Assets/images/icons/falseicon.svg"
import { ModalContext } from '../../Context'
import { useContext, useState } from "react";



const RemoveConfirmation = ({ title, data, action,id }) => {
    const context = useContext(ModalContext)
    const { closeModal } = context;
    const clickHandler = async () => {
        const res = await action(id);
    }

    return (
        <div className="warning-modal">
            <div className="flex items-center justify-center">
                <h3 className="title"> { title } </h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data }} />
    
            <div className="flex justify-between mx-auto mt-10">
                <Button className="btn2" onClick={closeModal}>
                    Cancel
                </Button>

                <Button
                    variant="outlined"
                    color="secondary"
                    className="approve_decline_padding danger-bg"
                    onClick={clickHandler}
                >
                    <span className="cross-icon" />
                    <span className="pl-1"> Remove </span>
                </Button>
            </div>
        </div>
    )
}

export default RemoveConfirmation;