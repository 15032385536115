import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import EditEventForm from '../ModalTemplate/EditEventForm';
import EventEditDeletePopUp from '../ModalTemplate/EventEditDeletePopUp';
import ReactCalendarPage from './ReactCalendarPage'
const AllEvent = ({ eventAllData, getEventApiData }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showEvent, setShowEvent] = useState([]);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    const formateTime = (time) => {
        const _Time = new Date(time);
        const hours = _Time.getHours();
        const minutes = _Time.getMinutes();
        const FullTime = `${hours}:${minutes}`
        return FullTime
    }

    const getAllApiDataOnLoad = () => {
        setShowEvent([])
        let arry = []
        eventAllData && eventAllData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(currentDate)
            if (_d1 === _d2) {
                arry.push(item)
            }
            else {
                //console.log(false);
                // setShowEvent([])
            }
        })

        setShowEvent(arry)

    }

    useEffect(() => {
        getAllApiDataOnLoad();
    }, [eventAllData])


    const dateHandler = (date) => {
        setCurrentDate(date)
        setShowEvent([])
        let arry = []
        eventAllData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(date)
            if (_d1 === _d2) {
                arry.push(item)
            }
            else {
                //console.log(false);
            }
            setShowEvent(arry)
        })
    }

    const handleActiveStartDate = (value) => {
        const _yearMonth = value.activeStartDate;
        const year = _yearMonth.getFullYear();
        const month = _yearMonth.getMonth() + 1;
        setYear(year)
        setMonth(month)
    }
    useEffect(() => {
        getEventApiData(year, month);
    }, [year, month]);

    //console.log(showEvent, "showEvent")

    const handleEditEventShow = (data, id) => {
        const edit_Form_Modal =
            // <EditEventForm
            //     title="Edit Event"
            //     buttonText="Save"
            //     getEventApiData={getEventApiData}
            //     eventDetails={data}
            //     eventId={id}
            // />
            <EventEditDeletePopUp
                title="Edit Event"
                buttonText="Save"
                getEventApiData={getEventApiData}
                eventDetails={data}
                eventId={id}
            />
        handleModalData(edit_Form_Modal, 'sm')  // Only I need to paist this in other event page not commented ki jagah
    }

    return (
        <div className="grid grid-cols-4">
            <div className="col-span-3">
                <ReactCalendarPage eventAllData ={eventAllData} showEvent ={showEvent}  value={currentDate} onChange={dateHandler} activeStartDateChange={handleActiveStartDate} />
                <div className="my-8 flex items-center">
                    <div className="flex items-center mr-4">
                        <span className="w-4 h-4 rounded-full bg-sidenav" />
                        <span className="text-paraText ml-2">LI Calendar</span>
                    </div>
                    <div className="flex items-center mx-4">
                        <span className="w-4 h-4 rounded-full bg-yellow-400" />
                        <span className="text-paraText ml-2">Mini Hub Calendar</span>
                    </div>
                    <div className="flex items-center mx-4">
                        <span className="w-4 h-4 rounded-full bg-muteText" />
                        <span className="text-paraText ml-2">User Deadlines</span>
                    </div>
                </div>
            </div>
            <div className="details_main">
                <div className="sticky top-0 sticky-sidebar">
                    <h2 className="text-sidenav text-2xl pb-2 pl-4">Details</h2>
                    {
                        showEvent.length > 0 ? (
                            showEvent.map((item , index) => (
                                <div 
                                 
                                    className={`p-5 cursor-pointer mb-5 ${item.postTo == "li" ? "bg-sidenav" : "bg-yellow-400"}`
                                    }
                                    key={item._id}
                                    onClick={() => handleEditEventShow(item, item._id)}
                                >
                                    <h3
                                        className={`text-xl ${item.postTo == "li" ? "text-white" : "text-minihubHeading"}`}>
                                        {item.name}
                                    </h3>
                                    <h3
                                        className={`${item.postTo == "li" ? "text-white" : "text-minihubPara"} `}
                                    >
                                        {item.description}
                                    </h3>
                                    <p className={`text-sm py-1 ${item.postTo == "li" ? "text-white" : "text-minihubPara"}`}> {`${formatDate(item.start)} to ${formatDate(item.end)}`} </p>
                                    <p
                                        className={`text-sm ${item.postTo == "li" ? "text-white" : "text-minihubPara"}`}
                                    >
                                        {/* {`${(item.startTime)} to ${(item.endTime)}`} */}
                                        {`${formateTime(item.startTime)} to ${formateTime(item.endTime)}`}
                                    </p>
                                    <p
                                        className={`text-sm py-1 ${item.postTo == "li" ? "text-white" : "text-minihubPara"}`}
                                    >
                                        {item.timezone}
                                    </p>
                                    <p
                                        className={`text_tosmall_10 ${item.postTo == "li" ? "text-white" : "text-minihubPara"}`}
                                    >
                                        {item && item.links && item.links.map((item2, index) => {
                                            return (
                                                <>
                                                    <div key={index}>
                                                        <p>{item2}</p>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </p>
                                </div>
                            ))

                        ) : (

                            <div className="ml-5">
                                <p>No Events for today</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AllEvent
