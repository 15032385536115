import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useRouteMatch } from "react-router-dom";
import HubDescription from '../Common/HubDescription';
import InnerTable from '../Common/InnerTable';
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { sectionsData, communityMember } from "./dummyData"
import { ModalContext } from '../../Context'
import MemberListing from "../ModalTemplate/MemberListing"
import AddSection from '../ModalTemplate/AddSection'
import plusIcon from "../../Assets/images/icons/plus.svg"
import AddMiniHub from '../ModalTemplate/AddMiniHub';
import HubsForm from '../ModalTemplate/HubsForm'
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from "../ModalTemplate/modalTextsFile"
import {
  submitMiniHub,
  fetchMembers,
  getSingleMiniHub,
  fetchMembersById,
  submitSection,
  getAdminTableData,
  editMiniHubApiHandler
} from './getRequests';
import { BASE_URL_IMG, deleteMiniHub } from "../../Utils/services"
import { toast } from "react-toastify";
import EditMinihub from '../ModalTemplate/EditMinihub';




const MiniHubDeatilPage = () => {
  const [selectedMember, setSelectedMember] = useState([])
  const [membersList, setMembersList] = useState([])
  const [miniHubData, setMiniHubData] = useState({})
  const [minihubName, setMinihubName] = useState("")
  const [minihubId, setMinihubId] = useState(""); //new for members id
  const [hubId, setHubId] = useState("")

  let match = useRouteMatch("/blog/:slug");
  let history = useHistory();
  const location = useLocation();
  const context = useContext(ModalContext)
  const { handleModalData, closeModal } = context;


  useEffect(() => {
    fetchMiniHubDetails()
  }, [])

  const handleHubPush = () => {
    history.goBack();
  }



  const fetchMiniHubDetails = async () => {
    const { id, name, hubId } = location.state;
    setMinihubId(id) // check this with hubId
    setHubId(hubId)
    setMinihubName(name)
    const res = await getSingleMiniHub(id)
    //console.log(res, id, 'SingleMiniHub');
    const formateDate = formatDate(res.minihub.createdOn)
    setMiniHubData({
      ...res,
      minihub: {
        ...res.minihub,
        formateDate
      }
    })
  }

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }
  //console.log(miniHubData)
  const EDIT_MINI_HUB = <EditMinihub
    title="EDIT MINI HUB"
    btnText="SAVE"
    // getMembers={fetchMembers}
    getMembers={fetchMembersById}
    // id={miniHubData.minihub && miniHubData.minihub._id}
    id={minihubId} //new 
    hubId = {hubId}
    miniHubDetails={miniHubData.minihub}
    fetchMiniHubDetails={fetchMiniHubDetails}
    edit
  />

  const editMiniHub = () => {
    handleModalData(EDIT_MINI_HUB)
  }

  const _removeMiniHub = async (id) => {
    try {
      const res = await deleteMiniHub(id)
      history.goBack()
      closeModal()
      toast.success(res.message);
    } catch (error) {
      toast.error(error.message);
    }
  }



  const removeMiniHub = () => {
    const REMOVE_MINI_HUB = <RemoveConfirmation
      title="REMOVE MINI HUB"
      data={modalTextsFile.miniHubRemoveText}
      action={_removeMiniHub}
      id={miniHubData.minihub._id}
    />

    handleModalData(REMOVE_MINI_HUB, 'sm')
  }

  const goTo = value => {
    const { _id, name } = value.row.original
    //console.log(_id);

    history.push({
      pathname: '/master/hub/minihub/section',
      state: { id: _id, name: name }
    })
  }



  const addSection = () => {
    const ADD_NEW_SECTION = <AddSection
      title="ADD NEW SECTION"
      btnText="ADD SECTION"
      miniHubId={miniHubData.minihub._id}
      fetchManagers={getAdminTableData}
      submitHandler={submitSection}
      refreshDataMethod={fetchMiniHubDetails}
      closeModal={closeModal}
    />

    handleModalData(ADD_NEW_SECTION)
  }


  const section = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },

      {
        Header: 'Section Name',
        accessor: 'name',
      },

      {
        Header: 'Manager',
        accessor: 'manager',
        Cell: ({ cell: { value } }) =>
          <div className="flex justify-start items-center ">
            <img src={`${BASE_URL_IMG}${value.image}?${new Date().getTime()}`} alt="" className="icon rounded-icon" />
            <span className="ml-2">{value.name}</span>
          </div>
      },

      {
        Header: 'Attachments',
        accessor: 'attachments',

      },

      {
        Header: 'Created On',
        accessor: 'createdOn',
        Cell: ({ cell: { value } }) =>
          <div className="flex justify-start items-center">
            <span> {formatDate(value)} </span>
          </div>
      },

      {
        id: "view",
        Header: "",
        accessor: "view",
        Cell: (props) => <Button className="btn2" onClick={() => goTo(props)}> View </Button>,
      }
    ],
    []
  )


  return (
    <>
      <div className="flex items-center mb-4 ml-10">
        <span onClick={handleHubPush} className="text-sm text-minihubPara cursor-pointer">Minihub</span>
        <span className="px-2 text-sm">/</span>
        <span className="text-sidenav capitalize text-sm">{minihubName}</span>
      </div>
      <div className="grid grid-cols-9 gap-x-40 bg-white py-5">
        {
          miniHubData.minihub &&
          <>
            <div className="col-span-3 sticky top-0 pl-10">
              <HubDescription
                title="Women Rights"
                editHandler={editMiniHub}
                removeHandler={removeMiniHub}
                data={miniHubData.minihub}
                removeBtn="REMOVE MINI HUB"
              />
            </div>

            <div className="col-span-6 sticky top-0">
              <div className="flex justify-between items-center">
                <h4 className="primary-color font-semibold"> Resources </h4>
                <ul className="flex items-center">
                  <li className="flex items-center">
                    <div className="rounded-full bg-green h-3 w-3 mr-3" />
                    Active
                    <span />
                  </li>

                  <li className="flex items-center ml-5">
                    <div className="rounded-full bg-inActive h-3 w-3 mr-3" />
                    Inactive
                    <span />
                  </li>
                </ul>

                <div className="flex items-center cursor-pointer" onClick={addSection}>
                  <h4 className="primary-color font-semibold"> Add New </h4>
                  <img src={plusIcon} alt="" className="mx-4 w-8" />
                </div>
              </div>
              <InnerTable columns={section} data={miniHubData.sections} />
            </div>
          </>
        }

      </div>
    </>

  )
}

export default MiniHubDeatilPage