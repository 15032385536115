import RemovedPost from "./RemovedPost"
import react, { useEffect, useState } from "react"
import { fetchRemovedFlag } from "../../Utils/services"
import { toast } from "react-toastify"



const RemovedTab = () => {
    const [removedFlagData, setRemovedFlagData] = useState([])
    
    const isEven = num => {
        return num % 2 === 0
    }


    useEffect(() => {
        getRemovedFlag()
    },[])
    

    const getRemovedFlag = async () => {
        try {
            const res = await fetchRemovedFlag()
            //console.log(res);
            setRemovedFlagData(res.data)
            // toast.success(res.message)
        } catch (error) {
            toast.success(error.message)
        }
    }

    return (
        <div className="bg-white primary-table-theme">
            {
                removedFlagData.length > 0 && (
                    <>
                        <ul className="head sticky top-0 grid grid-cols-2 gap-x-28 px-10 py-2">
                            <li> Post </li>
                            <li> Post </li>
                        </ul>

                        <div className="px-10 grid grid-cols-2 gap-x-28">
                            {
                                removedFlagData.map((item, index) => (
                                    <div key={index}>
                                        <RemovedPost
                                            data={item}
                                            divider={(isEven(index + 1))}
                                            getRemovedFlag={getRemovedFlag}
                                        />
                                        { (isEven(index + 1)) && <div className="row-border-bottom col-span-2" /> }
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default RemovedTab;