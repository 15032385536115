import { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { ModalContext } from '../../Context';
import { addRegion, BASE_URL_IMG, deleteRegion, editRegion } from '../../Utils/services';
import { toast } from 'react-toastify';
import { getRegionData } from "../Master/getRequests"
import RemoveConfirmation from "./RemoveConfirmation"
import modalTextsFile from "./modalTextsFile"
import { Button } from "@material-ui/core";
import Loader from "../Common/Loader"






const AddRegion = ({
    title,
    setRegionData,
    btnText,
    edit,
    id,
    name,
    image
}) => {
    const [fileName, setFileName] = useState(image || '');
    const [loader, setLoader] = useState(false);

    const [data, setData] = useState({
        flag: image || "",
        region: name || ""
    });


    const context = useContext(ModalContext);
    const { closeModal, handleModalData } = context;


    const delRequest = async (id) => {
        setLoader(true)
        let data = {
            "id": id
        }
        try {
            const result = await deleteRegion(data);

            if (result.res.success) {
                const _data = await getRegionData();
                setRegionData(_data);
                toast.success("Expertise Deleted!");
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.message);
        }
    }

    const REMOVE_CONFIRMATION = <RemoveConfirmation
        title="REMOVE REGION"
        data={modalTextsFile.regionRemoveText}
        action={delRequest}
        id={id}
    />


    const handleFile = (val) => {
        
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 210 && img.height === 140) {
                    setFileName(val.name);
                    setData({ ...data, "flag": val })
                }
                else {
                    setFileName("");
                    setData({ ...data, "flag": "" })
                }
            }
        }
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setData({ ...data, [name]: value })
    }

// THis is example
    const addRegionHandler = async () => {
        setLoader(true)
        const formData = new FormData();
        formData.append("name", data.region);
        formData.append("media", data.flag);

        try {
            const result = await addRegion(formData);
            if (result.res.success) {
                toast.success(result.res.message);
                const _data = await getRegionData();
                setRegionData(_data);
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.messages);
        }
    }


    const editHandler = async () => {
        setLoader(true)
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", data.region);
        if (typeof data.flag !== "string") {
            formData.append("media", data.flag);
        }

        try {
            const result = await editRegion(formData);
            if (result.res.success) {
                toast.success(result.res.message);
                const _data = await getRegionData();
                setRegionData(_data);
                setLoader(false)
                closeModal();

            }
        }
        catch (e) {
            setLoader(false)
            //console.log(e);
            toast.error(e.messages);
        }
    }




    return (
        <>
        <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full">
                <div className="flex flex-col w-full">
                    <div className="mb-4">
                        <TextField id="name" label="Name" className="w-full" name="region" value={data.region} onChange={(e) => handleChange(e)} />
                    </div>

                    <div className="mb-4">
                        <div className="relative w-auto overflow-hidden">
                            {
                                edit && typeof data.flag === "string" &&
                                <img src={`${BASE_URL_IMG}${data.flag}`} className="mb-1" style={{ maxWidth: "100px" }} />
                            }

                            <TextField
                                id="profilephoto"
                                label="Add a Region Flag"
                                value={fileName}
                                className="w-full text-xs relative"
                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="flag"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800">Note: Image dimensions should be 210x140px </p>
                    </div>
                </div>


                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="button" disabled ={loader ? true : false} onClick={edit ? editHandler : addRegionHandler}>
                        {btnText}
                    </button>
                </div>

                {
                    edit && (
                        <div className="flex justify-center mt-5">
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="approve_decline_padding"
                                onClick={() => handleModalData(REMOVE_CONFIRMATION, 'sm')}
                                disabled ={loader ? true : false}
                            >
                                <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                <span className="pl-1">Remove</span>
                            </Button>
                        </div>
                    )
                }
            </form>
        </>
    )
}

export default AddRegion;