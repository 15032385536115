import { Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { ModalContext } from '../../Context'
import { DeleteEvent } from '../../Utils/services'
import EditEventForm from './EditEventForm'
import modalTextsFile from './modalTextsFile'
import RemoveConfirmation from './RemoveConfirmation'

const EventEditDeletePopUp = ({
    title,
    buttonText,
    getEventApiData,
    eventDetails,
    eventId
}) => {
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [yearMonthData, setYearMonthData] = useState(new Date(eventDetails.current))

    const removeEvent = async () => {
        const _yearMonth = yearMonthData;
        const year = _yearMonth.getFullYear();
        const month = _yearMonth.getMonth() + 1;
        setYear(year)
        setMonth(month)
        try {
            const result = await DeleteEvent(eventId);
            if (result.res.success) {
                toast.success(result.res.message);
                closeModal();
                getEventApiData(year, month);
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleEditEvent = () => {
        const edit_Form_Modal =
            <EditEventForm
                title={title}
                buttonText={buttonText}
                getEventApiData={getEventApiData}
                eventDetails={eventDetails}
                eventId={eventId}
            />
        handleModalData(edit_Form_Modal)
    }

    const HandleRemoveEvent = () => {
        const REMOVE_CONFIRMATION = <RemoveConfirmation
            title="Remove Event"
            action={removeEvent}
            data={modalTextsFile.EventRemoveText}
            id={eventId}
        />
        handleModalData(REMOVE_CONFIRMATION, 'sm')
    }
    return (
        <div className="warning-modal">
            <div className="flex items-center justify-center">
                <h3 className="title"> Please Select What Do You Want </h3>
            </div>
            <div className="flex justify-between mx-auto mt-10">
                <Button className="btn2" onClick={handleEditEvent}>
                    Edit
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    className="approve_decline_padding danger-bg"
                    onClick={HandleRemoveEvent}
                >
                    <span className="cross-icon" />
                    <span className="pl-1"> Remove </span>
                </Button>
            </div>
        </div>
    )
}

export default EventEditDeletePopUp