import Form from "./Form"
import { useState } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';



const AffiliationForm = ({ title,setAffiliationData }) => {
    const [formType, setformType] = useState('party');

    const formTypeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setformType(value)
    }


    const renderForm = () => {
        switch (formType) {
            case 'party':
                return (
                    <Form
                        setAffiliationData={setAffiliationData}
                        btnText="ADD PARTY"
                        type={1}
                    />
                )

            case 'organisation':
                return (
                    <Form
                    setAffiliationData={setAffiliationData}
                        btnText="ADD ORGANISATION"
                        type={2}
                    />
                )

            case 'thinkTank':
                return (
                    <Form
                    setAffiliationData={setAffiliationData}
                        btnText="ADD THINK TANK"
                        type={3}
                    />
                )

            default:
                return (
                    <Form
                    setAffiliationData={setAffiliationData}
                    btnText="ADD PARTY"
                       
                    />
                )
        }
    }


    return (
        <>
            <div className="flex items-center justify-center">
                <h3 className="title"> { title } </h3>
            </div>

            <div className="mt-4 w-6/12 flex items-center justify-between mx-auto">
                <h1 className="label"> Affiliation Type: </h1>
                <RadioGroup
                    aria-label="formType"
                    name="formType"
                    className="radio-group"
                    value={formType}
                    onChange={e => formTypeHandler(e)}
                >
                    <FormControlLabel
                        value="party"
                        control={<Radio />}
                        className="radio-label"
                        label="Party"
                    />

                    <FormControlLabel
                        value="organisation"
                        control={<Radio />}
                        className="radio-label"
                        label="Organisation"
                    />

                    <FormControlLabel
                        value="thinkTank"
                        control={<Radio />}
                        className="radio-label"
                        label="Think Tank"
                    />
                </RadioGroup>
            </div>

            { renderForm() }
        </>
    )
}

export default AffiliationForm