import DescriptionBox from "../Common/DescriptionBox";
import Grid from "@material-ui/core/Grid";
import CommentSection from "../Common/CommentSection";
import { filterPostType } from "../../functions";
import { useEffect, useState } from "react";
import { singlePostApi } from "../../Utils/services";
import { toast } from "react-toastify";

const PostDetails = ({ data, reportHandler, isModalView, isReportBtn , removedPost }) => {
  //console.log(data, "data", reportHandler, isModalView, isReportBtn);
  const [singlePostData, setSinglePostData] = useState([]);

  const getSinglePostDetails = async () => {
    try {
      const result = await singlePostApi(data._id);
      if (result.res.success) {
        setSinglePostData(result.res.data);
        // toast.success(result.res.message)
      }
    } catch (error) {
      //console.log(error);
      toast.error(error);
    }
  };
  useEffect(() => {
    if(!removedPost){
      getSinglePostDetails();
    }else{
      setSinglePostData(data)
    }
    
  }, []);
  //console.log(singlePostData, "singlePostData");
  return (
    <>
      <div className="flex items-center justify-center">
        <h3 className="title"> POST DETAILS </h3>
      </div>
      <div style={{display:'flex' , justifyContent:'center' , gap:'1rem'}}>
        <Grid item md={8}>
          {filterPostType(
            singlePostData,
            reportHandler,
            isModalView,
            isReportBtn
          )}
        </Grid>
       {singlePostData?.comments?.[0] &&  <Grid item md="4" style={{marginLeft:'10px'}}>
          <CommentSection
            commentSectionData={data}
            singlePostData={singlePostData}
          />
        </Grid>}
      </div>
    </>
  );
};

export default PostDetails;
