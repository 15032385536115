import React, { useState, useMemo, useContext } from 'react';
import { Button, ListItem } from "@material-ui/core";
import PostDetail from "../Common/PostDetail"
import PostDetails from "../ModalTemplate/PostDetails"
import CommentSection from "./CommentSection";
import { ModalContext } from '../../Context'
import { deleteManageFlag, keepPost } from '../../Utils/services'
import { toast } from 'react-toastify';
import modalTextsFile from '../ModalTemplate/modalTextsFile'
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation'
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { filterPostType } from '../../functions';


const PostWrapper = ({ data, reRenderFlags }) => {
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;


    const reportHandler = () => {
        //console.log('reportHandler');
    }
    
    const showPostDetail = item => {
        const POST_MODAL = <PostDetails
            data={item}
            reportHandler={reportHandler}
            isReported={false}
            isModalView={true}
        />
        handleModalData(POST_MODAL)
    }

    const removeFlag = async (id) => {
        try {
            const body = { id: id }
            const res = await deleteManageFlag(body)
            closeModal()
            reRenderFlags()
            toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const removeHandler = () => {
        const REMOVE_CONFIRMATION = <RemoveConfirmation
            title="Remove Feed"
            data={modalTextsFile.flagRemoveText}
            action={removeFlag}
            id={data._id}
        />
        handleModalData(REMOVE_CONFIRMATION, 'sm')
    }

    const postHandler = async() => {
        try {
            const body = { id: data.postDetails._id }
            const res = await keepPost(body)
            reRenderFlags()
            toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }


    return (
        <>
            {
                data && (
                    
                    <div className="custom-row">
                        <div className="left">
                            { filterPostType(data, showPostDetail) }
                        </div>

                        <div className="right h-full">
                            <CommentSection data={data.flag.report} />
                            <div className="footer text-right">
                                <Button
                                    color="primary"
                                    className="primary-color"
                                    onClick={postHandler}
                                >
                                    keep post
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className="approve_decline_padding ml-10"
                                    onClick={removeHandler}
                                    >
                                    <img src="/assets/icons/falseicon.svg"  alt=""/>{" "}
                                    <span className="pl-1">Remove</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default PostWrapper;