import classNames from "classnames"

const SimpleListing = ({ title, list }) => {
    return (
        <div>
            <h1 className="text-xl tracking-wider primary-color font-semibold mb-2">
                { title }
            </h1>

            <ul className="p-10 pb-6 bg-white">
                {
                    list.map((data, index, array) => (
                        <li key={index} className={classNames({
                            "text-paraText font-semibold mb-5 pb-4 text-sm": true,
                            "border-b": ((array.length - 1) !== index)
                        })}>
                            { data.item } 
                        </li>
                    ))
                }
            </ul>
            
        </div>
    )
}

export default SimpleListing