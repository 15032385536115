import React, { useState, useMemo, useContext, useEffect } from 'react';
import { FEEDS_FORM, POST_MODAL } from '../ModalTemplate';
import { ModalContext } from '../../Context'
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import Table from '../Common/Table';
import { feedsData, postView } from './dummyData';
import { Button } from '@material-ui/core';
import flag from '../../Assets/images/flag.svg'
import MemberListing from "../ModalTemplate/MemberListing"
import { BASE_URL_IMG, getFeed, getPoll, getPost } from '../../Utils/services';
import { toast } from 'react-toastify';
import AllFeeds from './AllFeeds';
import PostFeed from './PostFeed';
import PollFeed from './PollFeed';
import Loader from "../Common/Loader"

const a11yProps = (index) => {
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Feeds = () => {

    const [tabsValue, setTabsValue] = useState(0);
    const [allFeedData, setAllFeedData] = useState(null);
    const [postData, setPostData] = useState(null);
    const [pollData, setPollData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [totalCount, setotalCount] = useState(0)

    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const getFeedData = async (sendData) => {
        try {
            const result = await getFeed(sendData);
            setLoader(false)
            setAllFeedData(result.res.data)
            const newData = result.res.data;
            setotalCount(result.res.total);
            const filterPollData = newData.filter((data) => {
                return data.isPoll === true
            })
            const filterPostData = newData.filter((data) => {
                return data.isPoll === false
            })
            // setPostData(filterPostData.reverse())
            // setPollData(filterPollData.reverse());
        }
        catch (error) {
            setLoader(false)
            //console.log(error)
            toast.error(error.message);
        }
    }
    const getPollData = async (sendData) => {
        try {
            const result = await getPoll(sendData);
            setPollData(result.res.reverse())
        }
        catch (error) {
            setLoader(false)
            //console.log(error)
            toast.error(error.message);
        }
    }
    const getPostData = async (sendData) => {
        try {
            const result = await getPost(sendData);
            setPostData(result.res.reverse())
        }
        catch (error) {
            setLoader(false)
            //console.log(error)
            toast.error(error.message);
        }
    }

    // useEffect(() => {
    //     getFeedData();
    // }, []);
    useEffect(() =>{
        // getPollData({
        //     page : 1,
        //     size : 10
        // });
        // getPostData({
        //     page : 1,
        //     size : 10
        // });
    }, []);

    //console.log(pollData, "pollData")

    const POST_VIEWS_MEMBER = <MemberListing
        data={postView}
        title="Post View"
    />

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
    };

    const viewFeed = () => {
        handleModalData(POST_MODAL)
    }

    const allColumns = useMemo(
        () => [
            {
                Header: 'Flag',
                accessor: 'col1', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) =>
                    <img src={flag} alt="" />,
            },
            {
                Header: 'Name',
                accessor: 'col2',
                Cell: ({ cell: { value } }) =>
                    <div className="flex justify-start items-center">
                        <img src={`${BASE_URL_IMG}${value.img}?${new Date().getTime()}`} alt="" />
                        <span className="ml-2">{value.name}</span>
                    </div>
            },
            {
                Header: 'Post Photo',
                accessor: 'col3',
                Cell: ({ cell: { value } }) =>
                    <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className="post-img" />
            },
            {
                Header: 'Posted In',
                accessor: 'col4',
            },
            {
                Header: 'Posted On',
                accessor: 'col5',
            },

            {
                Header: 'Number of Likes',
                accessor: 'col6',
            },
            {
                Header: 'Number of Comments',
                accessor: 'col7',
            },
            {
                Header: 'Number of Views',
                accessor: 'views',
                Cell: ({ cell: { value } }) => (
                    <div className="flex items-center">
                        <span> Views </span>
                        <img
                            src="/assets/icons/eye.svg"
                            alt=""
                            className="eye-icon"
                            onClick={() => handleModalData(POST_VIEWS_MEMBER)}
                        />
                    </div>
                )
            },
            {
                id: "questionID",
                Header: "Action",
                accessor: "questionID",
                Cell: ({ cell: { value } }) => <Button onClick={() => viewFeed()} className="btn2">View</Button>,
            }
        ], [handleModalData]);

    const pollColumns = useMemo(
        () => [
            {
                Header: 'Flag',
                accessor: 'col1', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) =>
                    <img src={flag} alt="" />,
            },
            {
                Header: 'Name',
                accessor: 'col2',
                Cell: ({ cell: { value } }) =>
                    <div className="flex justify-start items-center">
                        <img src={`${BASE_URL_IMG}${value.img}?${new Date().getTime()}`} alt="" />
                        <span className="ml-2">{value.name}</span>
                    </div>
            },
            {
                Header: 'Posted In',
                accessor: 'col3',
                Cell: ({ cell: { value } }) =>
                    <img src={`${BASE_URL_IMG}${value}?${new Date().getTime()}`} alt="" className="post-img" />
            },
            {
                Header: 'Location',
                accessor: 'col4',
            },
            {
                Header: 'Posted On',
                accessor: 'col5',
            },

            {
                Header: 'Number of Views',
                accessor: 'views',
            },
            {
                Header: 'Statistics',
                accessor: 'statistics',
            },
            {
                id: "questionID",
                Header: "Action",
                accessor: "questionID",
                Cell: ({ cell: { value } }) => <Button className="btn2">View</Button>,
            }
        ], []);

    const tabsData = [
        {
            label: "all",
            data: (
                <>
                    <Loader loader={loader} />
                    <AllFeeds totalCount ={totalCount} getFeedData={getFeedData} columns={allColumns} data={allFeedData} />
                </>
            )
        },

        {
            label: "post",
            data: <PostFeed totalCount ={totalCount} columns={allColumns} data={postData} getPostData={getPostData} />
        },

        {
            label: "poll",
            data: <PollFeed totalCount ={totalCount} columns={pollColumns} data={pollData} getPollData={getPollData} />
        }
    ]



    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(FEEDS_FORM(true, getFeedData));
                break;

            case 1:
                handleModalData(FEEDS_FORM(true, getFeedData));
                break;

            case 2:
                handleModalData(FEEDS_FORM(false, getFeedData));
                break;

            default:
                handleModalData(FEEDS_FORM(true, getFeedData));
                break;
        }
    }
    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />
            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10"
                        value={tabsValue}
                        index={index}
                        key={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}
export default Feeds;

